import React, { Fragment, useEffect, useState } from "react";
import { ArrowLeft, Eye, MessageCircle, Search } from "react-feather";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardFooter,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Collapse,
  Input,
  ButtonGroup,
  Form,
  FormGroup,
  CardImg,
  Badge,
} from "reactstrap";
import { getArticles } from "../../../services/articles";
// import parse from 'html-react-parser';
import sanitizeHtml from "sanitize-html";

import Pagination from "../../Pagination/Pagination";
import Loader from "../../Loading/Loader";
import "./style.css";
import { BaseApi } from "../../../services/config.json";
import localImage from "./../../../assets/images/noimage/noimage.jpeg";
// import ShowIamage from "../../ShowImage";
// import MyImage from "../../ShowImage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { toast } from "react-toastify";

const ShowArticle = () => {
  const [articleArray, setArticleArray] = useState([]);
  const [data, setData] = useState(null);

  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  console.log("history", history.location.pathname);
  const path= history.location.pathname
  // fetch all articles
  const fetchData = async (params) => {
    try {
      setLoading(true);
      const { data } = await getArticles({path,params});
      console.log("all articles", data);
      setData(data.data);
      setArticleArray(data.data.docs);
      setLoading(false);
    } catch (error) {
      toast.error("مشکلی پیش آمده");
      console.log(error);
    }
  };

  console.log('data',data,'data array',articleArray);
  //show more

  //handle filter page
  const filterChangeHandler = (newParams) => {
    console.log(newParams);
    setFilter({ ...filter, ...newParams });
    const allfilter = {
      ...filter,
      ...newParams,
    };
    console.log(allfilter);
    fetchData(allfilter);
  };

  
  //mount data
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);
  useEffect(()=>{
    window.scrollTo(0, 0);

  },[filter])
  //singleArticle make route
  const singleArticleHandler = (article) => {
    console.log(article._id);
    const titleSlug = article.title.split(" ").join("-");
    history.push({
      pathname: `/single-articel/${titleSlug}`,
      search: `?id=${article._id}`,
      state: { detail: article },
    });
  };
  
  return (
    <Fragment>
      {loading && <Loader true={true} />}
      <Row className="showAllArticle">
        <Col sm="12">
          <Card className="m-12 article-card">
            <CardHeader>
              <h5>مقالات</h5>
            </CardHeader>
            <CardBody>
              <Row className="m-6 d-block">
                <Col xl="4" md="6" sm="12 xl-50" className="mt-6">
                  <ButtonGroup
                  sm='d-flex flex-column'
                  className="button-group-articles"
                    onClick={(e) =>
                      filterChangeHandler({
                        page: "1",
                        createdAt: e.target.value,
                      })
                    }
                  >
                    <Button color="primary" disabled={true}>
                      زمان
                    </Button>
                    <Button color="primary" value="-1">
                      همه
                    </Button>
                    <Button color="primary" value="-1">
                      جدیدترین
                    </Button>
                    <Button color="primary" value="1">
                      قدیمی ترین
                    </Button>
                  </ButtonGroup>
                </Col>
                <Col xl="12" sm="12">
                  <Form className="form-show-articles mt-4 d-block">
                    <FormGroup className="m-0">
                      <Input
                        style={{ height: "60px" }}
                        className="form-control "
                        type="text"
                        placeholder="جست و جو"
                        onChange={(e) =>
                          filterChangeHandler({
                            page: "1",
                            createdAt: "-1",
                            q: e.target.value,
                          })
                        }
                      />
                      <i className="fa fa-search"></i>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row>
                {data &&
                  articleArray.map((article) => {
                    console.log(article);
                    return (
                      <Col
                        key={article.id}
                        sm="12"
                        xl="4"
                        md="6"
                        className="mt-4"
                      >
                        <Card style={{ height: "100%" }} className="m-12">
                          <CardHeader >
                            <h5>{article.title}</h5>
                          </CardHeader>
                          <CardBody className="articles-cardBody">
                            <div>
                              {" "}
                              {article.images ? (
                                <LazyLoadImage
                                  effect="blur"
                                  height={"200px"}
                                  src={`${BaseApi}${article.images[480]}`} // use normal <img> attributes as props
                                  width={"100%"}
                                  placeholderSrc={localImage}
                                />
                              ) : (
                                <img src={localImage} />
                              )}
                            </div>
                            
                            <div style={{textAlign:'justify',marginTop:'2px'}} className="summart">
                              {article.summary}...
                            </div>
                          </CardBody>
                          <CardFooter>
                            <h6 className="mb-0 articles-cardFooter">
                              <div className="mb-0 cardFooter-items">
                                <div className="cardFooter-item">
                                  <i className="fa fa-eye"></i>{" "}
                                  <span>{article.review}</span>
                                </div>
                                <div className="cardFooter-item">
                                  <i className="fa fa-comment"></i>{" "}
                                  <span>{article.commentsCount}</span>
                                </div>
                              </div>
                              <span
                                className="link cardFooter-item"
                                onClick={() => singleArticleHandler(article)}
                              >
                                <i className="fa fa-arrow-left"></i>{" "}
                                <span>ادامه</span>
                              </span>
                            </h6>
                          </CardFooter>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {data && (
        <Pagination
          totalData={data.total}
          currentPage={data.page}
          perPage="12"
          onPageChange={(page) => filterChangeHandler({ page })}
        />
      )}
      
    </Fragment>
  );
};

export default ShowArticle;
