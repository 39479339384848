import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Alert } from 'reactstrap';
import LoanList from './LoanList';
import Profile from './../UserPanel/Profile';
import { ab_UserPanel, ab_dashboard, ab_Profile, AB_BookList, ab_Hi, ab_WelComeToAryaBook } from './../Constant/NewConstant'
import { getApi } from './../../services/Api/api';
import { withRouter } from 'react-router'

const MainPanel = ({history}) => {

    const [activeTab, setActiveTab] = useState('1');
    const [activeTab2, setActiveTab2] = useState('1');
    const [activeTab3, setActiveTab3] = useState('1');

    const [data, setData] = useState([])

    const LoadData = async () => {
        const result = await getApi({ path: "users/me" });
        setData(result.data.data);
    };
    console.log(data)

    useEffect(() => {
        LoadData()
        setActiveTab2(localStorage.getItem("SetNavForAdminPanel") || "1")
        localStorage.removeItem("SetNavForAdminPanel")
    }, [window.location.pathname])
    return (
        <Fragment>
            <Breadcrumb parent="Bonus Ui" title={ab_UserPanel} />
            <Container fluid={true} className="PublicFont">
                <Row>

                    <Col lg="12 box-col-12" xl="12 xl-100">
                        <Card>
                            <CardHeader>
                                {/* <h5>چت</h5> */}
                            </CardHeader>
                            <CardBody className="tabbed-card UserPanelUl">

                                <Nav className="nav-pills nav-primary">
                                    <NavItem className='CurPoi'>
                                        <NavLink className={activeTab2 === '1' ? 'active' : ''} onClick={() => setActiveTab2('1')}>
                                            <i className="icofont icofont-ui-home"></i>{ab_dashboard}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className='CurPoi'>
                                        <NavLink className={activeTab2 === '2' ? 'active' : ''} onClick={() => setActiveTab2('2')}>
                                            <i className="icofont icofont-man-in-glasses"></i>{ab_Profile}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className='CurPoi'>
                                        <NavLink className={activeTab2 === '3' ? 'active' : ''} onClick={() => setActiveTab2('3')}>
                                            <i className="icofont icofont-contacts"></i>{AB_BookList}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab2}>
                                    <TabPane tabId="1">
                                        <Alert color="success dark" className="PublicFont">
                                            <p style={{ textAlign: "center", fontSize: "18px" }}>{ab_Hi} {data.name} {ab_WelComeToAryaBook}</p>
                                        </Alert>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Profile />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <LoanList />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>


                </Row>
            </Container>
        </Fragment>
    );
};

export default withRouter(MainPanel);