import React, { useState, useEffect, Fragment } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, Media, Form, FormGroup, Input,  Button, ModalBody, Label, ModalFooter, Modal, ModalHeader } from 'reactstrap'
import errorImg from '../../assets/images/search-not-found.png';
import { getApi, postApi } from './../../services/Api/api';
import { toast } from 'react-toastify';
import { BaseApi } from "./../../services/config.json";
import { withRouter } from 'react-router'
import {ab_AddedToFriendSucsesfuly, ab_NewMessage, ab_SaveMessage,  ab_phoneNumber, ab_Close, ab_AddFriend, ab_ShowInfo, ab_Info, ab_name, ab_Chat,  ab_WrightAndClickButton } from './../Constant/NewConstant'


const UserChat = ({ props, history }) => {

    const [Rooms, setRooms] = useState('');
    const [me, setMe] = useState('');

    const GetUserData = async () => {
        const GetMe = await getApi({
            path: "users/me"
        });
        setMe(GetMe.data?.data);
    }

    const GetUserRooms = async () => {
        // event.preventDefault();

        const result = await getApi({
            path: "rooms"
        });
        setRooms(result.data?.data);
    };

    useEffect(() => {
        GetUserData()
        GetUserRooms()
        var intervalRoom = setInterval(() => {
            GetUserRooms()
        }, 4000);

        return () => {
            clearInterval(intervalRoom)
        }

    }, []);



    const [friendInfo, setFriendInfo] = useState('');

    const [mobile, setMobile] = useState('');

    const FindByMobile = async () => {
        try {
            const result = await getApi({
                path: "users/findByMobile",
                params: {
                    mobile: mobile
                }
            });
            setFriendInfo(result.data?.data);
        }
        catch (error) {
            console.log(error);
            toast.error(error.response.data.error, {
                position: "top-right",
                closeOnClick: true
            });
        }
    }


    const AddFriend = async () => {
        const body = { to: friendInfo.id };
        try {
            const { status } = await postApi({
                path: "rooms",
                body
            });
            if (status === 201) {
                toast.success(ab_AddedToFriendSucsesfuly, {
                    position: "top-right",
                    closeOnClick: true
                });
            }
            VaryingContentonetoggle()
            GetUserRooms()
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.error, {
                position: "top-right",
                closeOnClick: true
            });
        }
    };


    const GetUserMessage = (item) => {
        const id = item.id;
        history.push({
            pathname: `/UserMessage/${id}`,
            state: item
        })
    }


    const [VaryingContentone, setVaryingContentone] = useState(false);
    const VaryingContentonetoggle = () => {
        setMobile()
        setFriendInfo()
        setVaryingContentone(!VaryingContentone);
    }


    return (
        <Fragment>
            <Breadcrumb parent="Apps" title={ab_Chat} />
            <Container fluid={true}>
                <Row>
                    <Col sm="12" md="12" className="call-chat-sidebar FixedWidth">
                        <Card>
                            <CardBody className="chat-body">
                                <div className="chat-box">
                                    <div className="chat-left-aside">
                                        <div className="media">
                                            <Media src={me?.profileImage ? `${BaseApi}${me?.profileImage?.minimal}` : require("../../assets/images/Landing/user.png")} className="rounded-circle user-image" alt="" />
                                            <div className="about">
                                                <div className="name f-w-600">{me.name}</div>
                                                <div className="status">
                                                    {me.mobile}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="people-list">
                                            <div className="m-t-15 m-b-15">
                                                <Button color="primary" onClick={VaryingContentonetoggle}>
                                                    {ab_AddFriend}
                                                    <img src={require('./../../assets/images/Landing/add-user.png')} alt="" style={{ width: "30px" }} />
                                                </Button>
                                            </div>
                                            <div className="search">

                                            </div>
                                            {Rooms.length > 0 ?
                                                <ul className="list">
                                                    {Rooms.map((item, i) => {
                                                        return (
                                                            <li className={`clearfix HoverRooms`}
                                                                key={i} onClick={() => GetUserMessage(item)} >
                                                                {item.from.id === me.id ?
                                                                    <Fragment>
                                                                        {item.to.id === me.id ?
                                                                            <img src={item.to?.profileImage ? `${BaseApi}${item.to?.profileImage.minimal}` : require('./../../assets/images/Landing/user.png')} className="rounded-circle user-image" alt="" />
                                                                            :
                                                                            <img src={item.to?.profileImage ? `${BaseApi}${item.to?.profileImage.minimal}` : require('./../../assets/images/Landing/user.png')} className="rounded-circle user-image" alt="" />
                                                                        }
                                                                    </Fragment>
                                                                    :
                                                                    <img src={item.from?.profileImage ? `${BaseApi}${item.from?.profileImage.minimal}` : require('./../../assets/images/Landing/user.png')} className="rounded-circle user-image" alt="" />
                                                                }
                                                                <div className="about">{item.from.id === me.id ?
                                                                    <Fragment>
                                                                        <div className="name">{item.to.id === me.id ? ab_SaveMessage : item.to.name} </div>
                                                                        <div className="status">
                                                                            {item?.to.mobile}
                                                                        </div>
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <div className="name">{item.from.name} </div>
                                                                        <div className="status">
                                                                            {item?.from.mobile}
                                                                        </div>
                                                                    </Fragment>
                                                                }

                                                                </div>
                                                                {item?.newMessage?.length ?
                                                                    <p className='newMessage'> {item?.newMessage?.length} {ab_NewMessage}</p>
                                                                    : ""}
                                                            </li>);
                                                    })
                                                    }
                                                </ul>
                                                :
                                                <Media className="img-fluid m-auto" src={errorImg} alt="" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>





            <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle}>
                <ModalHeader toggle={VaryingContentonetoggle}>
                    {ab_AddFriend}
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label className="col-form-label PublicFont" for="recipient-name">{ab_WrightAndClickButton}</Label>
                            <Input className="form-control InputSetting" type="text" placeholder={ab_phoneNumber}
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                            />
                        </FormGroup>
                        {mobile ?
                            <Button color="primary" className='PublicFont' onClick={FindByMobile}>{ab_ShowInfo}</Button>
                            : <Button color="primary" className='PublicFont' disabled>{ab_ShowInfo}</Button>}
                        {friendInfo ?
                            <FormGroup>
                                <Label className="col-form-label PublicFont" >{ab_Info} : </Label>
                                <table className="product-page-width">
                                    <tbody className="f-18">
                                        <tr>
                                            <td> <b>{ab_name} &nbsp;&nbsp;&nbsp;:</b></td>
                                            <td>{friendInfo?.name}</td>
                                        </tr>
                                        <tr>
                                            <td> <b>{ab_phoneNumber} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                            <td>{friendInfo.mobile}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </FormGroup>
                            : ""}
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={VaryingContentonetoggle}>{ab_Close}</Button>
                    {friendInfo ?
                        <Button color="primary" className='PublicFont' onClick={AddFriend}>{ab_AddFriend}</Button>
                        : <Button color="primary" className='PublicFont' disabled >{ab_AddFriend}</Button>
                    }
                </ModalFooter>
            </Modal>

        </Fragment>
    );
}

export default withRouter(UserChat);