import React, { Fragment, useState, useEffect } from 'react';
import { CardHeader, Card, Col, Table, Pagination, PaginationItem, PaginationLink, CardBody } from 'reactstrap'
import { getApi } from './../../services/Api/api';
import moment from 'jalali-moment'
import { ab_loansBook, ab_BookName, ab_author, ab_language, ab_createdAt, ab_deliveryDate, ab_Prev, ab_Next, ab_NumberOfResult } from './../Constant/NewConstant'
import Loader from '../Loading/Loader';


const LoanList = () => {

    const [LoanLists, setLoanLists] = useState([]);
    const [PaginateLL, setPaginateLL] = useState([]);
    const [pageNum, setpageNum] = useState(1);
    const [limitP, setlimitP] = useState(10);

    const LoadData = async (PostPgae) => {
        const result = await getApi({
            path: "loans/user",
            params: {
                page: PostPgae,
                limit: limitP
            }
        });
        CloseHandler()
        setLoanLists(result.data.data.docs);
        setPaginateLL(result.data.data);
    };
    console.log(LoanLists)
    console.log(PaginateLL)

    const Next = (pageNum) => {
        OpenHandler()
        let PostPgae = pageNum + 1;
        setpageNum(PostPgae)
        LoadData(PostPgae)
    }
    const Prv = (pageNum) => {
        OpenHandler()
        let PostPgae = pageNum - 1;
        setpageNum(PostPgae)
        LoadData(PostPgae)
    }

    useEffect(() => {
        LoadData()
    }, [])


    //Loading
    const [True, SetTrue] = useState(false)
    const CloseHandler = () => {
        SetTrue(false)
    }
    const OpenHandler = () => {
        SetTrue(true)
    }


    return (
        <Fragment>
            <Loader true={True} />
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <h5>{ab_loansBook}</h5>
                    </CardHeader>
                    <div className="card-block row">
                        <Col sm="12" lg="12" xl="12">
                            <div className="table-responsive">
                                <Table>
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">{"#"}</th>
                                            <th scope="col">{ab_BookName}</th>
                                            <th scope="col">{ab_author}</th>
                                            <th scope="col">{ab_language}</th>
                                            <th scope="col">{ab_createdAt}</th>
                                            <th scope="col">{ab_deliveryDate}</th>
                                            <th scope="col">{"وضعیت"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {LoanLists ? LoanLists.map((item, i) =>
                                            <tr key={i}>
                                                <th scope="row">{i + 1}</th>
                                                <td>{item?.book.name}</td>
                                                <td>{item?.book.author}</td>
                                                {item?.book.lang ?
                                                    <td>{item?.book.lang ? item?.book.lang.map((Langu) =>
                                                        <Fragment>
                                                            {Langu == "en" ?
                                                                <small>انگلیسی &nbsp;</small>
                                                                : ""}
                                                            {Langu == "pashto" ?
                                                                <small>پشتو &nbsp;</small>
                                                                : ""}
                                                            {Langu == "dari" ?
                                                                <small>دری &nbsp;</small>
                                                                : ""}
                                                        </Fragment>
                                                    ) : ''}

                                                    </td> : null}

                                                <td>{moment(item?.deliveryDate, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}</td>

                                                {item?.deliveredDate ?
                                                    <td>{moment(item?.deliveredDate, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}</td>
                                                    :
                                                    <td>تحویل داده نشده</td>
                                                }

                                                {item.delivery == true ?
                                                    < td >
                                                        <small style={{ color: "green" }}>
                                                            تحویل داده شده
                                                        </small>
                                                    </td>
                                                    :
                                                    <td>
                                                        <small style={{ color: "red" }}>
                                                            در انتظار تحویل
                                                        </small>
                                                    </td>

                                                }
                                            </tr>
                                        ) : ""}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <div style={{ height: "40px", width: "100%", marginRight: "10px" }}>  {ab_NumberOfResult} :  {PaginateLL.total}</div>

                    </div>
                </Card >
                <CardBody>
                    <Pagination aria-label="...">
                        <ul className="pagination pagination-primary">
                            {PaginateLL.page == 1 ? null :
                                <PaginationItem ><PaginationLink onClick={() => Prv(pageNum)}>{ab_Prev}</PaginationLink></PaginationItem>
                            }
                            <PaginationLink style={{ width: "100px" }} >
                                <span>{PaginateLL.page}</span> &nbsp; / &nbsp;
                                <span>{PaginateLL.pages}</span>
                            </PaginationLink>


                            <Fragment>
                                {PaginateLL.page == PaginateLL.pages ? null :
                                    <PaginationItem><PaginationLink onClick={() => Next(pageNum)}>{ab_Next}</PaginationLink></PaginationItem>
                                }
                            </Fragment>
                        </ul>
                    </Pagination>
                </CardBody>
            </Col >
        </Fragment >
    )

}

export default LoanList


