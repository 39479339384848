import React from "react";
import { range } from "lodash";
import './pagination.css' 

const Pagination = ({ totalData, currentPage, perPage, onPageChange }) => {
    console.log(totalData, currentPage, perPage);
    const pageCount = Math.ceil(totalData/ perPage);
    if (pageCount === 1) return null;

    const pages = range(1, pageCount + 1);
console.log(pages);
    return (
        <nav aria-label="Page navigation ">
            <ul className="pagination justify-content-center mb-5">
                {pages.map(page => (
                    <li
                        key={page}
                       
                    >
                        <a
                            className={`page-link ${ page ===parseInt( currentPage)
                                ? "active"
                                : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => onPageChange(page)}
                        >
                            {page}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Pagination;
