import React, { Fragment } from 'react';
import { Card, Col, CardHeader, CardBody } from 'reactstrap';
import BPcss from './../../assets/Css/BookPage.module.css'
import { ab_download, ab_AudioNotSuported, ab_Audio } from './../Constant/NewConstant'
import { BaseApi } from "./../../services/config.json";
import { Link } from 'react-router-dom';

const BooksAudio = (props) => {

    return (
        <Fragment>
            {props?.audio.length ?
                <div>
                    <Col xl="12 PublicFont">
                        <Card className="card-absolute">
                            <CardHeader className="bg-primary">
                                <h5>{ab_Audio}
                                    <img className={BPcss.TitrImg} src={require('./../././../assets/images/Landing/audio.png')} alt="" />
                                </h5>
                            </CardHeader>
                            <CardBody style={{ paddingTop: "50px" }}>
                                {props.audio.map((item, i) => {
                                    return (
                                        <Fragment key={i} >


                                            <div id="wrap" className='downloadBox'>
                                                <span className="f-18 PartTitr">{item.name}</span>

                                                {localStorage.getItem("jwtToken") ?
                                                    <a href={`${BaseApi}${item.path}`} target="_blank" rel="noreferrer" >
                                                        <div class="button65456">
                                                            {ab_download}
                                                            <span>
                                                                <span></span>
                                                            </span>
                                                        </div>
                                                    </a>
                                                    :
                                                    <Link to={`/pages/auth/login`}>
                                                        <div className='buttonLogeOut'>
                                                            برای دانلود لطفا وارد شوید
                                                        </div>
                                                    </Link>
                                                }
                                            </div>


                                            <audio className={BPcss.Audio} controls preload="none">
                                                <source src={`${BaseApi}${item.path}`} type="audio/ogg" />
                                                {ab_AudioNotSuported}
                                            </audio>
                                        </Fragment>
                                    )
                                })}
                            </CardBody>
                        </Card>
                    </Col>
                </div>
                : ''}
        </Fragment>
    );
}

export default BooksAudio;