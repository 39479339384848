import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Collapse,
  CardBody,
  CardHeader,
} from "reactstrap";
import { getApi, postApi, putApi } from "./../../services/Api/api";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { Search } from "react-feather";
import {
  ab_AdedToFavorite,
  ab_LoginForAddToFavorite,
  AB_BookList,
  AB_all,
  ab_new,
  ab_old,
  AB_ShowBok,
  AB_AddToFavorite,
  AB_AdvancedSearch,
  AB_mostVisited,
  AB_MostDownloaded,
  AB_Search,
  AB_NotFound,
  AB_Category,
} from "./../Constant/NewConstant";
import Loader from "../Loading/Loader";
import { useLocation } from "react-router-dom";
import { BaseApi } from "./../../services/config.json";
import { useHistory } from "react-router-dom";

const BookListComponent = (props) => {
  let history = useHistory();

  const [bookList, setBookList] = useState([]);
  const [bookPagination, setBookPagination] = useState([]);

  const GetBookList = async (parametr) => {
    OpenHandler();
    try {
      const result = await getApi({
        path: "books/search",
        params:
          { ...filter, ...(parametr.params ? parametr.params : {}) } || {},
      });
      setFilter({ ...filter, ...parametr.params });
      CloseHandler();
      setBookPagination(result.data.data);

      if (parametr.params.page == 1) {
        setBookList(result.data.data.docs);
      } else {
        const newData = result.data.data.docs ? [...result.data.data.docs] : [];
        const allData = [...bookList, ...newData];

        setBookList(allData);
      }
    } catch (error) {
      console.log(error);
      CloseHandler();
    }
  };

  //FilterBook
  const [filter, setFilter] = useState({});

  const handleFilter = (parametr) => {
    const filterQuery = {
      ...parametr.filter,
      limit: 12,
    };
    GetBookList({ params: filterQuery });
  };

  const [pageNumber, setPageNumber] = useState(1);

  const ShowMore = () => {
    let BookPageNumber = pageNumber + 1;
    setPageNumber(BookPageNumber);
    handleFilter({
      filter: { page: BookPageNumber },
    });
  };

  const [Category, setCategory] = useState([]);
  const GetCategory = async () => {
    const result = await getApi({ path: "categories/whitOuthPaginate" });
    setCategory(result.data.data);
  };

  const [categories, setCategories] = useState([]);

  const CategoryHandler = async (e) => {
    if (categories.indexOf(e) == -1) {
      categories.push(e);
    } else {
      categories.splice(categories.indexOf(e), 1);
    }
    handleFilter({
      filter: { categories: categories, page: 1 },
    });
  };

  //Search
  const [search, setSearch] = useState("");
  const filteredCategory = Category.filter((data) =>
    data.name.includes(search)
  );

  const { pathname } = useLocation();

  useEffect(() => {
    GetBookList({});
    GetCategory();
    DefaultSearchFilter();
    window.scrollTo(0, 0);
  }, [pathname]);

  const DefaultSearchFilter = () => {
    handleFilter({
      filter: { q: localStorage.getItem("Usersearch") || "" },
    });

    handleFilter({
      filter: { categories: localStorage.getItem("categoryId") || [] },
    });
  };

  const BookPage = (item) => {
    const id = item.id;
    const slug = item.slug;
    IncreaseView(id);
    history.push({
      pathname: `/BookPage/${slug}`,
      search: `?id=${id}`,
      state: { detail: item },
    });
  };

  const IncreaseView = async (id) => {
    try {
      await putApi({ path: `/books/${id}/inc-review` });
    } catch (error) {
      console.log(error);
    }
  };

  const AddToFavorites = async (item) => {
    OpenHandler();
    const body = { book: item };
    setTimeout(() => {
      CloseHandler();
    }, 10000);
    if (!localStorage.getItem("jwtToken")) {
      toast.error(ab_LoginForAddToFavorite, {
        position: "top-right",
        closeOnClick: true,
      });
      CloseHandler();
    } else {
      try {
        const { status } = await postApi({
          path: "favorites",
          body,
        });
        if (status === 201) {
          toast.success(ab_AdedToFavorite, {
            position: "top-right",
            closeOnClick: true,
          });
        }
      } catch (error) {
        toast.error(error.response.data.error, {
          position: "top-right",
          closeOnClick: true,
        });
      }
      CloseHandler();
    }
  };

  const [layoutColumns, setLayoutColumns] = useState(3);
  const [isFilter, setIsFilter] = useState(false);

  //General Filter
  const [showAll, setShowAll] = useState(false);
  const [oldest, setOldest] = useState(false);
  const [newest, setNewest] = useState(false);
  const [mostVisited, setMostVisited] = useState(false);
  const [mostDownloaded, setMostDownloaded] = useState(false);

  const ClearAll = () => {
    handleFilter({
      filter: { page: 1, type: "", review: "", downloades: "", createdAt: "" },
    });
    setShowAll(true);
    setOldest(false);
    setNewest(false);
    setMostVisited(false);
    setMostDownloaded(false);
  };

  const JustNewest = () => {
    handleFilter({
      filter: { page: 1, review: "", downloades: "", createdAt: -1 },
    });
    setShowAll(false);
    setOldest(false);
    setNewest(true);
    setMostVisited(false);
    setMostDownloaded(false);
  };

  const JustOldest = () => {
    handleFilter({
      filter: { page: 1, review: "", downloades: "", createdAt: 1 },
    });
    setShowAll(false);
    setOldest(true);
    setNewest(false);
    setMostVisited(false);
    setMostDownloaded(false);
  };
  const JustMostVisited = () => {
    handleFilter({
      filter: { page: 1, review: -1, downloades: "", createdAt: "" },
    });
    setShowAll(false);
    setMostVisited(true);
    setOldest(false);
    setNewest(false);
    setMostDownloaded(false);
  };
  const JustMostDownloaded = () => {
    handleFilter({
      filter: { page: 1, review: "", downloades: -1, createdAt: 1 },
    });
    setShowAll(false);
    setMostDownloaded(true);
    setOldest(false);
    setNewest(false);
    setMostVisited(false);
  };

  //Loading
  const [True, SetTrue] = useState(false);
  const CloseHandler = () => {
    SetTrue(false);
  };
  const OpenHandler = () => {
    SetTrue(true);
  };

  return (
    <Fragment>
      <Breadcrumb parent="Ecommerce" title={AB_BookList} />
      <Loader true={True} />
      <Container fluid={true} className="product-wrapper">
        <div className="product-grid">
          <div className="feature-products">
            <Row>
              <div
                className="text-center m-b-25"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Nav tabs className="search-list PublicFont CurPoi">
                  <NavItem className="bg-light-secondary FilterSearch">
                    <NavLink
                      className={
                        showAll ? "txt-secondary active" : "txt-secondary"
                      }
                      onClick={() => {
                        ClearAll();
                      }}
                    >
                      {/* <i className="icon-target"></i> */}
                      {AB_all}
                    </NavLink>
                  </NavItem>
                  <NavItem className="FilterSearch">
                    <NavLink
                      className={newest ? "active" : ""}
                      onClick={() => {
                        JustNewest();
                      }}
                    >
                      {/* <i className="icon-image"></i> */}
                      {ab_new}
                    </NavLink>
                  </NavItem>
                  <NavItem className="FilterSearch">
                    <NavLink
                      className={oldest ? "active" : ""}
                      onClick={() => {
                        JustOldest();
                      }}
                    >
                      {/* <i className="icon-video-clapper"></i> */}
                      {ab_old}
                    </NavLink>
                  </NavItem>
                  <NavItem className="FilterSearch">
                    <NavLink
                      className={mostVisited ? "active" : ""}
                      onClick={() => {
                        JustMostVisited();
                      }}
                    >
                      {/* <i className="icon-eye"></i> */}
                      {AB_mostVisited}
                    </NavLink>
                  </NavItem>

                  <NavItem className="FilterSearch">
                    <NavLink
                      className={mostDownloaded ? "active" : ""}
                      onClick={() => {
                        JustMostDownloaded();
                      }}
                    >
                      {/* <i className="icon-download"></i> */}
                      {AB_MostDownloaded}
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Row>
            <Row>
              <Fragment>
                <Col xl="3" sm="12">
                  <div
                    className="default-according style-1 faq-accordion job-accordion"
                    id="accordionoc"
                  >
                    <Row>
                      <Col xl="12" style={{ position: "relative" }}>
                        <Card>
                          <CardHeader>
                            <h5 className="mb-0">
                              <Button
                                className="PublicFont"
                                color="link pl-0"
                                data-toggle="collapse"
                                onClick={() => setIsFilter(!isFilter)}
                                data-target="#collapseicon"
                                aria-expanded={isFilter}
                                aria-controls="collapseicon"
                              >
                                {AB_AdvancedSearch}
                              </Button>
                            </h5>
                            <Collapse
                              isOpen={isFilter}
                              style={{
                                position: "absolute",
                                left: "0px",
                                top: "0px",
                                background: " rgb(255, 255, 255)",
                                zIndex: "300",
                                width: "100%",
                                boxSizing: "border-box",
                                marginTop: "70px",
                                maxHeight: "400px",
                                overflowY: "scroll",
                                direction: "rtl",
                                boxShadow: "0px 0px 5px 0px #666",
                              }}
                            >
                              <div
                                className="collapse show"
                                id="collapseicon"
                                aria-labelledby="collapseicon"
                                data-parent="#accordion"
                              >
                                <CardBody className="filter-cards-view animate-chk">
                                  <div className="job-filter">
                                    <select
                                      className="form-control btn-square PublicFont m-b-15"
                                      name="type"
                                      onChange={(e) => {
                                        handleFilter({
                                          filter: {
                                            type: e.target.value,
                                            page: 1,
                                          },
                                        });
                                      }}
                                    >
                                      <option value="" className="PublicFont">
                                        نوع کتاب ( پیشفرض همه )
                                      </option>
                                      <option
                                        value="both"
                                        className="PublicFont"
                                      >
                                        خواندنی و صوتی
                                      </option>
                                      <option
                                        value="audio"
                                        className="PublicFont"
                                      >
                                        صوتی
                                      </option>
                                      <option
                                        value="read"
                                        className="PublicFont"
                                      >
                                        خواندنی
                                      </option>
                                    </select>
                                    <select
                                      className="form-control btn-square PublicFont m-b-15"
                                      name="lang"
                                      onChange={(e) => {
                                        handleFilter({
                                          filter: {
                                            lang: e.target.value,
                                            page: 1,
                                          },
                                        });
                                      }}
                                    >
                                      <option value="" className="PublicFont">
                                        {" "}
                                        زبان ( پیشفرض همه )
                                      </option>
                                      <option value="en" className="PublicFont">
                                        انگلیسی
                                      </option>
                                      <option
                                        value="dari"
                                        className="PublicFont"
                                      >
                                        دری
                                      </option>
                                      <option
                                        value="pashto"
                                        className="PublicFont"
                                      >
                                        پشتو
                                      </option>
                                    </select>
                                    <div className="faq-form">
                                      <Input
                                        className="form-control PublicFont"
                                        type="search"
                                        placeholder={AB_Search}
                                        onChange={(e) =>
                                          setSearch(e.target.value)
                                        }
                                      />
                                      <Search className="search-icon" />
                                    </div>
                                  </div>
                                  <div className="checkbox-animated">
                                    <div className="learning-header">
                                      <span className="f-w-600">
                                        {AB_Category}
                                      </span>
                                    </div>

                                    {filteredCategory.length
                                      ? filteredCategory.map((item, index) => (
                                          <Label
                                            className="d-block PublicFont"
                                            htmlFor="chk-ani"
                                            key={index}
                                          >
                                            <Input
                                              className="checkbox_animated"
                                              defaultChecked={
                                                localStorage.getItem(
                                                  "categoryId"
                                                ) === item._id
                                              }
                                              id="chk-ani"
                                              type="checkbox"
                                              value={item._id}
                                              onClick={(e) =>
                                                CategoryHandler(e.target.value)
                                              }
                                            />
                                            {item.name}
                                          </Label>
                                        ))
                                      : ""}
                                  </div>
                                </CardBody>
                              </div>
                            </Collapse>
                          </CardHeader>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Fragment>

              <Col xl="9" sm="12">
                <Form>
                  <FormGroup className="m-0">
                    <Input
                      style={{ height: "70px" }}
                      className="form-control PublicFont"
                      type="text"
                      defaultValue={localStorage.getItem("Usersearch")}
                      placeholder={AB_Search}
                      onChange={(e) => {
                        (e.target.value.length > 4 &&
                          e.target.value.length === 0) ||
                          handleFilter({
                            filter: { q: e.target.value, page: 1 },
                          });
                      }}
                    />
                    <i
                      className="fa fa-search"
                      style={{ marginTop: "10px" }}
                    ></i>
                    <Button type="submit" style={{ display: "none" }}></Button>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </div>

          <div className="product-wrapper-grid">
            <Row className="gridRow">
              {bookList.length ? (
                bookList.map((item, i) => (
                  <div
                    className={`${
                      layoutColumns === 3
                        ? "col-xl-3 col-sm-6 xl-4 col-grid-box"
                        : "col-xl-" + layoutColumns
                    }`}
                    key={i}
                  >
                    <Card>
                      <div className="product-box">
                        <div className="product-img">
                          <img
                            className="img-fluid FixBookListHeight"
                            src={
                              item.images[0]
                                ? `${BaseApi}${item.images[0]}`
                                : require("./../../assets/images/Landing/NoBook.png")
                            }
                            alt=""
                          />
                          <div className="product-hover">
                            <ul>
                              <li
                                className="ToolBox"
                                onClick={() => BookPage(item)}
                              >
                                <span className="ToolBoxText">
                                  {AB_ShowBok}
                                </span>
                                <Button
                                  color="default"
                                  data-toggle="modal"
                                  detail={AB_ShowBok}
                                >
                                  <i className="icon-eye"></i>
                                </Button>
                              </li>
                              <li
                                className="ToolBox"
                                onClick={() => AddToFavorites(item.id)}
                              >
                                <span className="ToolBoxText">
                                  {AB_AddToFavorite}
                                </span>
                                <Button color="default">
                                  <i className="icon-heart"></i>
                                </Button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="product-details">
                          <h4 className="font-primary InputSetting">
                            {item.name}
                          </h4>
                            <p className="PublicFont">{item.author}</p>
                            <div className={`product-price ${!item.author&&'empty-author'} `}>
                              {item.audio.length > 0 ||
                              item.files.length > 0 ? (
                                <>
                                  <span
                                    className="ViDbackground"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <i className="fa fa-download">&nbsp;</i>
                                    {item.downloades}
                                  </span>{" "}
                                  &nbsp;
                                </>
                              ) : (
                                ""
                              )}
                              <span
                                className="ViDbackground"
                                style={{ fontSize: "14px" }}
                              >
                                <i className="fa fa-eye">&nbsp;</i>
                                {item.review}
                              </span>
                              &nbsp;
                              <span
                                className="ViDbackground"
                                style={{ fontSize: "14px" }}
                              >
                                <i className="fa fa-comment">&nbsp;</i>
                                {item.commentsCount}
                              </span>
                            </div>
                          </div>
                      </div>
                    </Card>
                  </div>
                ))
              ) : (
                <Row style={{ margin: "0", padding: "0" }}>
                  <Col>
                    <p className="NoResult">{AB_NotFound}</p>
                  </Col>
                </Row>
              )}
            </Row>
          </div>
          {bookPagination.page == bookPagination.pages ? null : (
            <div id="container">
              <button
                class="learn-more buttonss"
                onClick={() => ShowMore(pageNumber)}
              >
                <span class="circle" aria-hidden="true">
                  <span class="icon arrow"></span>
                </span>
                <span class="button-text PublicFont">مشاهده بیشتر</span>
              </button>
            </div>
          )}
        </div>
      </Container>
    </Fragment>
  );
};

export default withRouter(BookListComponent);
