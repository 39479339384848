import React, { useState, useEffect, Fragment } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, Button, Form, FormGroup, Input } from 'reactstrap'
import { deleteApi, getApi } from '../../services/Api/api';
import { withRouter } from 'react-router'
import { toast } from 'react-toastify';
import { ab_LoginForRemoveFromFavorite, ab_RemoveFromFavorite, AB_Search, AB_ShowBok, AB_NotFound, AB_ShowMore, AB_NoAddFavorite, AB_RemoveFromFavorite, AB_Favorite } from '../Constant/NewConstant'
import Loader from '../Loading/Loader';
import { useLocation } from 'react-router-dom'
import { BaseApi } from "../../services/config.json";


const FavoritesBook = ({ props, history }) => {

  const [favoritesList, setFavoritesList] = useState([]);
  const [favoritesPagination, setFavoritesPagination] = useState([]);

  const GetUserFavorites = async (FavoritesPageNumber) => {
    const result = await getApi({
      path: "favorites",
      params: {
        page: FavoritesPageNumber,
        limit: 12
      }
    });
    setFavoritesPagination(result.data?.data);
    if (FavoritesPageNumber === 1) {
      setFavoritesList(result.data.data.docs)
    }
    else {
      const newData = result.data.data.docs ? [...result.data.data.docs] : []
      const allData = [...favoritesList, ...newData]
      setFavoritesList(allData)
    }
  };


  const [PageNumber, setPageNumber] = useState(1)

  const ShowMore = () => {
    let FavoritesPageNumber = PageNumber + 1;
    setPageNumber(FavoritesPageNumber)
    GetUserFavorites(FavoritesPageNumber)
  }

  const [search, setSearch] = useState("");
  const filteredFavorite = favoritesList.filter(data => data.book.name.includes(search))

  const { pathname } = useLocation();

  useEffect(() => {
    const FavoritesPageNumber = 1
    GetUserFavorites(FavoritesPageNumber)
    window.scrollTo(0, 0);
  }, [pathname])


  const BookPage = (item) => {
    const id = item.book._id;
    const slug = item.book.slug
    history.push({
      pathname: `/BookPage/${slug}`,
      search: `?id=${id}`,
      state: { detail: item }
    });
  };


  const RemoveFromFavorites = async (item) => {
    OpenHandler()
    if (!localStorage.getItem("jwtToken")) {
      toast.error(ab_LoginForRemoveFromFavorite, {
        position: "top-right",
        closeOnClick: true
      });
      CloseHandler()
    } else {
      try {
        const { status } = await deleteApi({
          path: `favorites/${item.id}`
        });
        CloseHandler()
        const FavoritesPageNumber = 1
        GetUserFavorites(FavoritesPageNumber)
        setPageNumber(1)
        if (status === 200) {
          toast.success(ab_RemoveFromFavorite, {
            position: "top-right",
            closeOnClick: true
          });
        }

      } catch (error) {
        console.log(error);
        toast.error(error.response.data.error, {
          position: "top-right",
          closeOnClick: true
        });
        CloseHandler()
      }
    }
  };


  // eslint-disable-next-line 
  const [layoutColumns, setLayoutColumns] = useState(3);

  //Loading
  const [True, SetTrue] = useState(false)
  const CloseHandler = () => {
    SetTrue(false)
  }
  const OpenHandler = () => {
    SetTrue(true)
  }


  return (
    <Fragment>
      <Breadcrumb parent="Ecommerce" title={AB_Favorite} />
      <Loader true={True} />
      <Container fluid={true} className="product-wrapper">
        <div className="product-grid">
          <div className="feature-products">
            <Row>
              <Col xl="12" sm="12">
                <Form>
                  <FormGroup className="m-0">
                    <Input
                      style={{ height: "70px" }}
                      className="form-control PublicFont"
                      type="text"
                      defaultValue={localStorage.getItem('Usersearch')}
                      placeholder={AB_Search}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <i className="fa fa-search" style={{ marginTop: "10px" }}></i>
                    <Button type="submit" style={{ display: "none" }}></Button>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </div>

          <div className="product-wrapper-grid">
            <Row className="gridRow">
              {favoritesList.length ?
                <Fragment>
                  {filteredFavorite.length ? filteredFavorite.map((item, i) =>
                    <div className={`${layoutColumns === 3 ? 'col-xl-3 col-sm-6 xl-4 col-grid-box' : 'col-xl-' + layoutColumns}`} key={i}>
                      <Card>
                        <div className="product-box">
                          <div className="product-img">

                            <img className="img-fluid FixBookListHeight" src={item?.book.images[0] ? `${BaseApi}${item.book.images[0]}` : require('./../../assets/images/Landing/NoBook.png')} alt="" />
                            <div className="product-hover">
                              <ul>
                                <li className="ToolBox" onClick={() => BookPage(item)}>
                                  <span className="ToolBoxText">{AB_ShowBok}</span>
                                  <Button color="default" data-toggle="modal" detail={AB_ShowBok}>
                                    <i className="icon-eye"></i>
                                  </Button>
                                </li>
                                <li className="ToolBox" onClick={() => RemoveFromFavorites(item)}>
                                  <span className="ToolBoxText">{AB_RemoveFromFavorite}</span>
                                  <Button color="default" style={{ paddingBottom: "5px", paddingRight: "2px" }}>
                                    <i className="fa fa-times">
                                    </i>
                                  </Button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-details">

                            <h4 className="font-primary InputSetting" >{item?.book.name}</h4>
                            <p className="PublicFont">{item?.book.author}</p>
                            <div className="product-price">
                              <span className="ViDbackground" style={{ fontSize: "14px" }}><i className="fa fa-download" >&nbsp;</i>{item?.book.downloades}</span>&nbsp;
                              <span className="ViDbackground" style={{ fontSize: "14px" }}><i className="fa fa-eye" >&nbsp;</i>{item?.book.review}</span>

                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ) :
                    <Row style={{ margin: "0", padding: "0" }}>
                      <Col>
                        <p className="NoResult">{AB_NotFound}</p>
                      </Col>
                    </Row>
                  }
                </Fragment> :
                <Row style={{ margin: "0", padding: "0" }}>
                  <Col>
                    <p className="NoResult">{AB_NoAddFavorite} </p>
                  </Col>
                </Row>
              }
            </Row>
          </div>
          {favoritesPagination.page == favoritesPagination.pages ? null :

            <div id="container">
              <button class="learn-more buttonss" onClick={() => ShowMore(PageNumber)}>
                <span class="circle" aria-hidden="true">
                  <span class="icon arrow"></span>
                </span>
                <span class="button-text PublicFont">مشاهده بیشتر</span>
              </button>
            </div>


          }
        </div>
      </Container>
    </Fragment >
  );
}

export default withRouter(FavoritesBook);