//Default
export const NotVideoSuported = "مرورگر شما ویدئو با این پسوند را پشتیبانی نمی کند."
export const ab_sb = "جست و جوی کتاب"
export const ab_Show = "مشاهده"
export const ab_More = "بیشتر"

//BookList
export const AB_BookList = "لیست کتاب ها"
export const AB_all = "همه"
export const ab_new = "جدید ترین ها"
export const ab_old = "قدیمی ترین ها"
export const AB_mostVisited = "پر بازدید ترین"
export const AB_Old = "قدیمی ترین ها"
export const AB_AddToFavorite = "افزودن به علاقه مندی ها"
export const AB_ShowBok = "نمایش کتاب"
export const AB_AdvancedSearch = "جست و جو پیشرفته"
export const AB_Search = "جست و جو"
export const AB_Category = "دسته بندی"
export const AB_NoCategury = "بدون دسته بندی"
export const AB_NotFound = "موردی یافت نشد"
export const AB_NotResult = "موردی برای نمایش وجود ندارد"
export const AB_ShowMore = "مشاهده بیشتر"
export const AB_MostDownloaded = "بیشترین دانلود"
export const AB_Heading = "سرفصل ها"

//Favorite
export const AB_Favorite = "علاقه مندی ها"
export const AB_RemoveFromFavorite = "حذف از علاقه مندی ها"
export const AB_NoAddFavorite = "شما هنوز کتابی به لیست علاقه مندی ها اضافه نکردید ."
export const AB_AdditionalExplanations = " جزئیات بیشتر"
export const AB_discription = "توضیحات"
export const AB_Translator = "مترجم"
export const AB_BookNumber = "تعداد صفحات"
export const AB_ReadTime = "زمان خواندن"


//BookPage
export const ab_bookInfo = "توضیحات کتاب"
export const ab_BookName = "نام کتاب"
export const ab_author = "نویسنده"
export const ab_language = "زبان"
export const ab_date = "سال انتشار"
export const ab_Rating = "امتیاز"
export const ab_Rate = "امتیاز"
export const ab_part = "بخش"
export const ab_SendToOther = "ارسال برای دیگران"
export const ab_SelectPerson = "لطفا یک دوست را انتخاب کنید"
export const ab_AudioNotSuported = "مرورگر شما فایل صوتی با این پسوند را پشتیبانی نمی کند."
export const ab_Audio = "فایل های صوتی"
export const ab_Click_Here = "کلیک کنید"
export const ab_download = "دانلود"
export const ab_Section = "قسمت"
export const ab_PdfFiles = "فایل های PDF"
export const ab_Close = "بستن"
export const ab_Send = "ارسال"
export const ab_MassageText = "متن پیام"
export const ab_SaveMessage = "پیام های ذخیره"
export const ab_OfTheTotal = "مجموع آرا"
export const ab_Vote = "رای"
export const ab_SendComment = "ارسال نظر"
export const ab_Comments = "نظرات"
export const ab_LoginToSendComment = "برای ثبت نظر ابتدا وارد شوید"
export const ab_SendAnswer = "ثبت پاسخ"
export const ab_FirstPersonToSendComment = "شما اولین نفری باشید که نظر خود را ثبت میکند"


//Panel
export const ab_UserPanel = "پنل کاربری"
export const ab_dashboard = "داشبورد"
export const ab_Profile = "پروفایل"
export const ab_MyProfile = "پروفایل من"
export const ab_role = "رول"
export const ab_phoneNumber = "شماره موبایل"
export const abUpdateProfile = "بروزرسانی پروفایل"
export const ab_takhalos = "تخلص"
export const ab_name = "نام"
export const ab_loansBook = "لیست کتاب های قرض گرفته شده"
export const ab_deliveryDate = "تاریخ تحویل"
export const ab_createdAt = "سررسید تحویل"
export const ab_NumberOfResult = "تعداد نتایج"
export const ab_Next = "بعدی"
export const ab_Prev = "قبلی"
export const ab_Hi = "سلام"
export const ab_WelComeToAryaBook = "عزیز ، به کتاب خانه مرکزی غالب خوش آمدید."
export const ab_active = "فعال"
export const ab_DeActive = "غیر فعال"
export const ab_ProfileImage = "عکس پروفایل"
export const ab_UploadImage = "آپلود عکس"
export const ab_EditProfile = "ویرایش پروفایل"

//Chat
export const ab_Chat = "چت"
export const ab_AddFriend = "افزودن دوست"
export const ab_NewMessage = "پیام جدید"
export const ab_GoBack = "بازگشت"
export const ab_WrightYourMessage = "پیام خود را بنویسید ..."
export const ab_WrightAndClickButton = "در ابتدا شماره موبایل را وارد نمایید، بعد روی نمایش بیشتر کلیک کنید"
export const ab_ShowInfo = "نمایش اطلاعات"
export const ab_Info = "مشخصات"


//Toast
export const ab_LoginForAddToFavorite = "برای افزودن به علاقه مندی ها ابتدا وارد شوید"
export const ab_AdedToFavorite = "به لیست علاقه مندی شما اضافه شد"
export const ab_YourRateSaved = "امتیاز شما ثبت شد"
export const ab_LoginForSaveRate = "برای ثبت امتیاز ها ابتدا وارد شوید"
export const ab_PostSendSucsesfuly = "پست با موفقیت ارسال شد"
export const ab_LoginForRemoveFromFavorite = "برای حذف از علاقه مندی ها ابتدا وارد شوید"
export const ab_RemoveFromFavorite = "از لیست علاقه مندی شما حذف شد"
export const ab_AddedToFriendSucsesfuly = "باموفقیت به دوستان اضافه شد"
export const ab_PleaseEnterYourMessage = "لطفا پیام خود را وارد کنید"
export const ab_UploadImageSucsesfuly = "آپلود عکس با موفقیت انجام شد"
export const ab_ProblemInUploadImage = "مشکل در آپلود عکس"
export const ab_YourInformationEditedSucsesfuly = "اطلاعات شما با موفقیت ویرایش شد"



