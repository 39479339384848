import { Home, Book, MessageSquare, LogIn, User, Heart, Users, Edit, List, Paperclip } from 'react-feather'

export const MENUITEMS = [
    {
        menutitle: "عمومی",
        menucontent: "",
        Items: [
            { path: ``, icon: Home, badge: "badge badge-info", title: 'صفحه اصلی', type: 'link', auth: false, Show: true },
            { path: `/BookList`, icon: Book, badge: "badge badge-info", title: 'لیست کتاب ها', type: 'link', auth: false, Show: true },
            { path: `/FavoritesBook`, icon: Heart, badge: "badge badge-info", title: 'علاقه مندی ها', type: 'link', auth: false, Show: false },
            { path: `/MainPanel`, icon: User, badge: "badge badge-info", title: 'پنل کاربری', type: 'link', auth: false, Show: false },
            { path: `/Chat`, icon: MessageSquare, badge: "badge badge-info", title: 'چت', type: 'link', auth: false, Show: false },
            { path: `/articels`, icon: Paperclip, badge: "badge badge-info", title: 'مقالات', type: 'link', auth: false, show: true },
            { path: `/user/add-articel`, icon: Edit, badge: "badge badge-info", title: 'ثبت مقاله', type: 'link', auth: false, Show: false },
            { path: `/user/my-articels`, icon: List, badge: "badge badge-info", title: 'مقاله های من', type: 'link', auth: false, Show: false },
            { path: `/ContactUs`, icon: Users, badge: "badge badge-info", title: 'درباره من', type: 'link', auth: false, Show: true },
            { path: `/pages/auth/login`, icon: LogIn, badge: "badge badge-info", title: 'ورود', type: 'link', auth: true, Show: false },
        ]
    }
]