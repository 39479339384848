import React, { Fragment, useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, Button, Badge, Input, Media, CardBody, Label, Form, FormGroup, ModalBody, ModalHeader, Modal, ModalFooter } from 'reactstrap'
import Slider from 'react-slick';
import { useDispatch } from 'react-redux'
import Ratings from 'react-ratings-declarative'
import { getApi, postApi } from './../../services/Api/api';
import Comment from './../Comment/Comment';
import BooksAudio from './BooksAudio';
import PdfFile from './PdfFile';
import { toast } from 'react-toastify';
import { ab_PostSendSucsesfuly, ab_AdedToFavorite, AB_NotFound, ab_LoginForAddToFavorite, ab_LoginForSaveRate, ab_YourRateSaved, ab_author, ab_language, ab_date, ab_SaveMessage, ab_Rating, ab_OfTheTotal, ab_Vote, ab_MassageText, ab_Rate, ab_Close, ab_Send, ab_SendToOther, ab_SelectPerson, AB_Category, AB_NoCategury, AB_AddToFavorite, ab_bookInfo, ab_BookName } from './../Constant/NewConstant'
import { useLocation } from 'react-router-dom'
import { BaseApi } from "./../../services/config.json";
import BookInfo from './BookInfo';

const BookPage = (props) => {

    const { pathname } = useLocation();

    const [me, setMe] = useState('');
    const [Book, setBook] = useState([]);

    const GetBookInfo = async () => {
        const result = await getApi({
            path: `${"books/"}${props?.location?.search.slice(4)}`
        });
        setBook(result.data.data || {});
    };

    const [Rates, setRates] = useState([]);
    const GetBookRate = async () => {
        const result = await getApi({
            path: "bookRates",
            params: {
                book: props?.location?.search.slice(4)
            }
        });
        setRates(result.data);
        setRating(result?.data?.rate || 0)
    };


    useEffect(() => {
        GetBookInfo()
        GetBookRate()
        if (localStorage.getItem("jwtToken")) {
            GetUserRooms()
        }

        window.scrollTo(0, 0);
    }, [pathname])


    const [state, setState] = useState({ nav1: null, nav2: null });
    const [rating, setRating] = useState(0)
    // eslint-disable-next-line

    const slider1 = useRef();
    const slider2 = useRef();
    const dispatch = useDispatch()

    useEffect(() => {
        setState({
            nav1: slider1.current,
            nav2: slider2.current
        });
    }, [dispatch]);

    const { nav1, nav2 } = state;



    const changeRating = async (newRating) => {
        setRating(newRating)

        const body = {
            book: Book.id,
            rate: newRating
        };
        if (!localStorage.getItem("jwtToken")) {
            toast.error(ab_LoginForSaveRate, {
                position: "top-right",
                closeOnClick: true
            });
        } else {
            try {
                const { status } = await postApi({
                    path: "bookRates",
                    body
                });
                if (status === 201) {
                    toast.success(ab_YourRateSaved, {
                        position: "top-right",
                        closeOnClick: true
                    });
                }
                GetBookRate()
            } catch (error) {
                toast.error(error.response.data.error, {
                    position: "top-right",
                    closeOnClick: true
                });
            }
        }
    };

    const AddToFavorites = async () => {
        const body = { book: Book.id };
        if (!localStorage.getItem("jwtToken")) {
            toast.error(ab_LoginForAddToFavorite, {
                position: "top-right",
                closeOnClick: true
            });
        } else {
            try {
                const { status } = await postApi({
                    path: "favorites",
                    body
                });
                if (status === 201) {
                    toast.success(ab_AdedToFavorite, {
                        position: "top-right",
                        closeOnClick: true
                    });
                }

            } catch (error) {
                console.log(error);
                toast.error(error.response.data.error, {
                    position: "top-right",
                    closeOnClick: true
                });
            }
        }
    };


    const [rooms, setRooms] = useState('');

    const GetUserRooms = async () => {
        const GetMe = await getApi({
            path: "users/me"
        });
        setMe(GetMe.data?.data);

        const result = await getApi({
            path: "rooms"
        });
        setRooms(result.data?.data);
    };

    const [RoomId, setRoomId] = useState('');
    const [message, setMessage] = useState('');

    const SendMessage = async event => {
        const body = { filePath: props?.location?.search.slice(4), message, room: RoomId };
        try {
            const { status } = await postApi({
                path: `messages`,
                body
            });
            if (status === 201) {
                toast.success(ab_PostSendSucsesfuly, {
                    position: "top-right",
                    closeOnClick: true
                });
            }
            VaryingContentonetoggle()
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.error, {
                position: "top-right",
                closeOnClick: true
            });
        }
    };



    const [VaryingContentone, setVaryingContentone] = useState(false);
    const VaryingContentonetoggle = () => {
        setVaryingContentone(!VaryingContentone);
    }


    return (
        <Fragment>
            <Breadcrumb parent="Ecommerce" title={Book.name} />
            <Container fluid={true}>
                <Row>
                    <Col>
                        <Card>
                            <Row className="product-page-main">
                                <Col xl="4">
                                    <Slider
                                        asNavFor={nav2}
                                        arrows={false}
                                        ref={slider => (slider1.current = slider)} className="product-slider">
                                        {Book?.images?.length ? Book.images.map((item, i) => {
                                            return (
                                                <div className="item" key={i}>
                                                    <Media src={`${BaseApi}${item}`} alt="" className="img-fluid FixBookListHeightL" />
                                                </div>
                                            )
                                        }) :
                                            <Media src={require('./../../assets/images/Landing/NoBook.png')} alt="" className="img-fluid FixBookListHeight-S" />
                                        }
                                    </Slider>
                                    <Slider asNavFor={nav1}
                                        ref={slider => (slider2.current = slider)}
                                        slidesToShow={Book.images?.length}
                                        swipeToSlide={true}
                                        focusOnSelect={true}
                                        infinite={true}
                                        className="small-slick">
                                        {Book.images ? Book.images.map((item, i) => {
                                            return (
                                                <div className="item" key={i}>
                                                    <Media className="img-fluid " id="bel" src={`${BaseApi}${item}`} alt="" style={{ height: "165px", margin: "0 auto" }} />
                                                </div>
                                            )
                                        }) : ''}
                                    </Slider>
                                </Col>
                                <Col xl="8">
                                    <Card>
                                        <CardBody className={"PublicFont"}>
                                            <div className="product-page-details">
                                                <h3>{ab_bookInfo}</h3>
                                            </div>
                                            <div>
                                                <table className="product-page-width">
                                                    <tbody className="f-18">
                                                        <tr>
                                                            <td> <b>{ab_BookName} &nbsp;&nbsp;&nbsp;:</b></td>
                                                            <td>{Book.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td> <b>{ab_author} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                                            <td className="txt-success">{Book.author}</td>
                                                        </tr>
                                                        <tr>
                                                            <td> <b>{ab_language} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                                            {Book.lang ?
                                                                <td>{Book.lang ? Book.lang.map((Langu, index) =>
                                                                    <Fragment key={index}>
                                                                        {Langu === "en" ?
                                                                            <small>انگلیسی &nbsp;</small>
                                                                            : ""}
                                                                        {Langu === "pashto" ?
                                                                            <small>پشتو &nbsp;</small>
                                                                            : ""}
                                                                        {Langu === "dari" ?
                                                                            <small>دری &nbsp;</small>
                                                                            : ""}
                                                                    </Fragment>
                                                                ) : ''}

                                                                </td> : null}




                                                        </tr>
                                                        <tr>
                                                            <td> <b>{ab_date} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                                            <td>{Book?.releaseDate || "-"}</td>

                                                        </tr>


                                                        <tr>
                                                            <td> <b>{AB_Category} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                                            {/* <td>
                                                                <Badge color="info" pill>{"Info"}</Badge>
                                                            </td> */}
                                                            {Book.categories ?
                                                                <td>{Book.categories ? Book.categories.map((Categurii, index) =>
                                                                    <Badge color="primary" pill>{Categurii.name}</Badge>
                                                                ) : ''}

                                                                </td> :
                                                                <Badge color="info" pill>{AB_NoCategury}</Badge>

                                                            }

                                                        </tr>


                                                        <tr>
                                                            <td className="p-t-15 p-b-15" style={{ fontSize: "14px" }}>

                                                                {Book?.audio?.length > 0 || Book?.files?.length > 0 ?
                                                                    <b className="ViDbackground"> <i className="fa fa-download"></i> &nbsp;{Book.downloades}</b>
                                                                    : ""}

                                                                <b className="ViDbackground" style={{ fontSize: "14px", marginRight: "10px" }}><i className="fa fa-eye"></i> &nbsp;{Book.review}</b>
                                                            </td>
                                                        </tr>

                                                        {/* <tr>
                                                            <td> <b className="ViDbackground"><i className="fa fa-eye"></i> &nbsp;{Book.review}</b></td>
                                                            <td>   </td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <hr />
                                            <Row>
                                                <Col md="3">
                                                    <h6 className="product-title" >{ab_Rating}</h6>

                                                </Col>
                                                <Col md="9">
                                                    <div className="d-flex">
                                                        <Ratings
                                                            rating={rating}
                                                            widgetRatedColors="gold"
                                                            changeRating={changeRating}
                                                        >
                                                            <Ratings.Widget />
                                                            <Ratings.Widget />
                                                            <Ratings.Widget />
                                                            <Ratings.Widget />
                                                            <Ratings.Widget />
                                                        </Ratings>

                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <span> {ab_OfTheTotal} : {Rates?.totalRate} {ab_Vote}</span>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <div className="m-t-15">
                                                {localStorage.getItem("jwtToken") ?
                                                    <Button color="success" className="m-r-10" onClick={VaryingContentonetoggle}>
                                                        <i className="fa fa-send mr-1"></i>{ab_SendToOther}
                                                    </Button>
                                                    : ""}
                                                <Button color="secondary" onClick={() => AddToFavorites()}>
                                                    <i className="fa fa-heart mr-1"></i>{AB_AddToFavorite}
                                                </Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                        <BookInfo data={Book} />
                        {Book.audio ?
                            <BooksAudio audio={Book.audio} />
                            : ''}
                        {Book.audio ?
                            <PdfFile File={Book.files} Id={props?.location?.search.slice(4)} />
                            : ''}
                        <Comment Id={props?.location?.search.slice(4)} CommentsCount={Book.commentsCount} />
                    </Col>
                </Row>
            </Container>








            <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle}>
                <ModalHeader toggle={VaryingContentonetoggle}>
                    {ab_SendToOther}
                </ModalHeader>
                <ModalBody>
                    {rooms.length > 0 ?
                        <Form>
                            <FormGroup>
                                <select className="form-control btn-square PublicFont" name="role" value={RoomId} onChange={e => setRoomId(e.target.value)}>
                                    <option value="" className="PublicFont" >{ab_SelectPerson} </option>

                                    {rooms.map((item, i) => {
                                        return (
                                            <Fragment key={i}>
                                                {item.from._id == me._id ?
                                                    <option value={item._id} className="PublicFont" style={{ direction: "rtl" }}>{item.to._id == me._id ? ab_SaveMessage : item.to.name} &nbsp;&nbsp;     ({item?.to.mobile})</option>
                                                    :
                                                    <option value={item._id} className="PublicFont" style={{ direction: "rtl" }}>{item.from.name} &nbsp;&nbsp;     ({item?.from.mobile})</option>
                                                }
                                            </Fragment>
                                        );
                                    })}

                                </select>
                            </FormGroup>
                        </Form>
                        :
                        <Row>
                            <Col>
                                <p className="NoResult">{AB_NotFound}</p>
                            </Col>
                        </Row>
                    }

                    {RoomId ?
                        <FormGroup>
                            <Label className="col-form-label" >{ab_MassageText} : </Label>
                            <Input type="textarea" className="form-control PublicFont" id="message-text" placeholder={ab_MassageText}
                                value={message}
                                onChange={e => setMessage(e.target.value)}

                            ></Input>
                        </FormGroup>
                        : ""}



                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={VaryingContentonetoggle}>{ab_Close}</Button>
                    <Button color="primary" onClick={SendMessage}>{ab_Send}</Button>
                </ModalFooter>
            </Modal>









        </Fragment>
    );
}
export default BookPage
