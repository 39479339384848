import React, { useState, useEffect, Fragment } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, Media, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap'
import start_conversion from '../../assets/images/start-conversion.png';
import { Picker } from 'emoji-mart'
import { getApi, patchApi, postApi } from './../../services/Api/api';
import { toast } from 'react-toastify';
import { BaseApi } from "./../../services/config.json";
import { withRouter } from 'react-router'
import moment from 'jalali-moment'
import { ab_PleaseEnterYourMessage, ab_GoBack, ab_SaveMessage, ab_Send, ab_Chat, ab_WrightYourMessage } from './../Constant/NewConstant'


const UserMessage = ({ props, history }) => {

    const [me, setMe] = useState('');
    const [personInfo, setPersonInfo] = useState('');
    const [userMessage, setUserMessage] = useState('');
    const [num, setnum] = useState('');

    const GetUserData = async () => {
        const GetMe = await getApi({
            path: "users/me"
        });
        setMe(GetMe.data?.data);
    }


    const GetFirstRoomById = async () => {
        const result = await getApi({
            path: `rooms/${(window.location?.pathname).split("/")[2]}`
        });
        setPersonInfo(result.data?.data);
        GetOnlineMassage(result.data?.data)
    };

    const GetRoomById = async () => {
        const result = await getApi({
            path: `rooms/${(window.location?.pathname).split("/")[2]}`
        });
        setPersonInfo(result.data?.data);
        NextGetOnlineMassage()
        if (result.data?.data?.newMessage?.length > 0) {
            SeenMessage()
        }
    };


    useEffect(() => {
        GetUserData()
        GetFirstRoomById()
        var intervalRoom = setInterval(() => {
            GetRoomById()
        }, 4000);

        return () => {
            clearInterval(intervalRoom)
        }
    }, []);


    const HandleShow = () => {
        history.push({
            pathname: `/Chat`
        })
    };


    const SendMessage = async () => {
        if (message === "") {
            toast.error(ab_PleaseEnterYourMessage)
        }
        else {
            const body = { message, room: (window.location?.pathname).split("/")[2] };
            try {
                const { status } = await postApi({
                    path: "messages",
                    body
                });
                if (status === 201) {
                    console.log("CM Send")
                }
                setMessage("")
                NextGetOnlineMassage()

            } catch (error) {
                console.log(error);

            }
        }
    };


    const GetOnlineMassage = async (item) => {
        const result = await getApi({
            path: `messages/room/${(window.location?.pathname).split("/")[2]}`
        });
        setUserMessage(result.data?.data.reverse());
        if (item?.newMessage.length > 0) {
            var FilteredMessage = result.data?.data.filter(function (item) {
                return item.seen == true;
            });
            setnum(FilteredMessage.length)
            window.open("#NotSeen", "_self")
            SeenMessage()
        }
        else {
            setnum(result.data?.data.length)
            window.open("#NotSeen", "_self")
        }
    }


    const NextGetOnlineMassage = async () => {
        const result = await getApi({
            path: `messages/room/${(window.location?.pathname).split("/")[2]}`
        });
        setUserMessage(result.data?.data.reverse());
    }


    const [message, setMessage] = useState('');

    const handleMessagePress = (e) => {
        if (e.key === "Enter" || e === "send") {
            SendMessage()
        }
    }


    const SeenMessage = async () => {
        const body = { room: (window.location?.pathname).split("/")[2] };
        try {
            const { status } = await patchApi({
                path: "messages/seen",
                body
            });
            if (status === 200) {
                console.log("seen")
            }
        } catch (error) {
            console.log(error);

        }
    };



    const [showEmojiPicker, setShowEmojiPicker] = useState(false)

    const toggleEmojiPicker = () => {
        setShowEmojiPicker(!showEmojiPicker);
    }

    const addEmoji = (emoji) => {
        const text = `${message}${emoji.native}`;
        setShowEmojiPicker(false);
        setMessage(text)
    }

    const BookPage = (item) => {
        const id = item.filePath;
        const slug = item.slug
        history.push({
          pathname: `/BookPage/Book`,
          search: `?id=${id}`,
          state: { detail: item }
        });
      };


    return (
        <Fragment>
            <Breadcrumb parent="Apps" title={ab_Chat} />
            <Container fluid={true}>
                <Row>
                    <Col className="call-chat-body">
                        <Card>
                            <CardBody className="p-0">
                                <Row className="chat-box">
                                    <Col className="pr-0 chat-right-aside ChatWidth" >
                                        <div className="chat">
                                            <p className='backtochat' onClick={HandleShow}>{ab_GoBack}</p>

                                            <div className="chat-header clearfix">

                                                {personInfo?.from?.id === me.id ?
                                                    <Fragment>
                                                        {personInfo?.to?.id === me.id ?
                                                            <Media src={personInfo?.to?.profileImage ? `${BaseApi}${personInfo?.to?.profileImage.minimal}` : require('./../../assets/images/Landing/user.png')} className="rounded-circle" alt="" />
                                                            :
                                                            <Media src={personInfo?.to?.profileImage ? `${BaseApi}${personInfo?.to?.profileImage.minimal}` : require('./../../assets/images/Landing/user.png')} className="rounded-circle" alt="" />
                                                        }
                                                    </Fragment>
                                                    :
                                                    <Media src={personInfo?.from?.profileImage ? `${BaseApi}${personInfo?.from?.profileImage.minimal}` : require('./../../assets/images/Landing/user.png')} className="rounded-circle" alt="" />
                                                }
                                                {/* <Media src={require("../../assets/images/Landing/user.png")} className="rounded-circle" alt="" /> */}



                                                <div className="about">

                                                    {personInfo?.from?.id === me.id ?
                                                        <Fragment>
                                                            <div className="name">{personInfo?.to?.id === me.id ? ab_SaveMessage : personInfo.to.name} </div>
                                                            <div className="status">
                                                                {personInfo?.to?.mobile}
                                                            </div>
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                            <div className="name">{personInfo?.from?.name} </div>
                                                            <div className="status digits">
                                                                {personInfo?.from?.mobile}
                                                            </div>
                                                        </Fragment>
                                                    }
                                                </div>
                                            </div>
                                            <div className="chat-history chat-msg-box custom-scrollbar AbMaxHeight" >
                                                <ul>
                                                    {userMessage ? userMessage.map((item, index) => {
                                                        // const participators = allMembers.chats.find(x => x.id === item.sender);
                                                        return (
                                                            <li key={index} className="clearfix" id={index == (num - 1) ? "NotSeen" : ""}>
                                                                <div className={`message my-message ${item.user.mobile == me.mobile ? '' : 'float-right'}`} style={{ boxShadow: "0px 0px 5px -2px #7a7a7a" }}>
                                                                    <Media src={item.user?.profileImage ? `${BaseApi}${item.user?.profileImage.minimal}` : require('./../../assets/images/Landing/user.png')}
                                                                        className={`rounded-circle ${item.user.mobile == me.mobile ? 'float-left' : 'float-right'} chat-user-img img-30`} alt="" />
                                                                    <div className="message-data text-right">
                                                                        <span className="message-data-time">
                                                                            {moment(item?.createdAt, 'YYYY/MM/DD HH:mm').locale('fa').format('YYYY/MM/DD HH:mm')}
                                                                        </span>
                                                                    </div>
                                                                    {item.message}

                                                                    {item?.filePath ?
                                                                        <Row>
                                                                            <Button color="primary" type="submit" onClick={() => BookPage(item)} >مشاهده پست</Button>
                                                                        </Row>
                                                                        : ""}
                                                                </div>
                                                            </li>
                                                        );
                                                    }) :
                                                        <div>
                                                            <Media className="img-fluid" src={start_conversion} alt="start conversion " />
                                                        </div>
                                                    }
                                                </ul>
                                            </div>
                                            <div className="chat-message clearfix">
                                                <Row>
                                                    <div className="mb-2">
                                                        {showEmojiPicker ? (
                                                            <Picker set="apple" emojiSize={30} onSelect={addEmoji} />
                                                        ) : null}
                                                    </div>
                                                    <Col xl="12" className="d-flex">
                                                        <div className="smiley-box bg-primary">

                                                            <div className="picker" onClick={() => toggleEmojiPicker()}>
                                                                <Media src={require('../../assets/images/smiley.png')} alt="" />
                                                            </div>

                                                        </div>
                                                        <InputGroup className="text-box">
                                                            <Input
                                                                type="text"
                                                                className="form-control input-txt-bx"
                                                                placeholder={ab_WrightYourMessage}
                                                                value={message}
                                                                onKeyPress={(e) => handleMessagePress(e)}
                                                                onChange={(e) => setMessage(e.target.value)}
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <Button color="primary"
                                                                    onClick={() => handleMessagePress('send')}
                                                                >{ab_Send}</Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </Fragment>
    );
}

export default withRouter(UserMessage);