import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap'
import MobailNav from './mobileNav';
const Footer = (props) => {
  console.log('footer');
  return (
    <Fragment>
      <div  >
        <MobailNav />
      </div>
      <footer className="footer FooterCR  ">
        <Container fluid={true}>
          <Row>
            <a href="http://omidshayan.info" target="_blank" rel="noreferrer">
              <Col md="12" className="footer-copyright text-center FooterCRText">
                <p className="mb-0">.COPYRIGHT 2021 © OMID SHAYAN</p>
              </Col>
            </a>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
}

export default Footer;