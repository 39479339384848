import React, { Fragment, useEffect, useState } from "react";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import swal from "sweetalert";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Badge,
} from "reactstrap";
import JoditEditor from "jodit-react";
import {
  editUserArticleData,
  getSingleData,
  postArticle,
} from "../../../services/articles";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ImageUploader from "react-images-upload";
import { LazyLoadImage } from "react-lazy-load-image-component";
import localImage from "../../../assets/images/noimage/noimage.jpeg";
import { BaseApi } from "../../../services/config.json";
import { Trash2 } from "react-feather";
import "./style.css";
const ArticleForm = ({ match }) => {
  const [textEditoreData, setTextEditoreData] = useState("");
  const [title, setTitle] = useState("");
  const [selectFile, setSelectFile] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [image, setImage] = useState(null);
  const [summary,setSummary]=useState('')
  const location = useLocation();
  const id = location.search.split("=")[1];
  const formData = new FormData();
  //textEditor change
  const handleChange = (textEditoreData) => {
    setTextEditoreData(textEditoreData);
  };
  //get single article data by id
  const getSingleArticle = async () => {
    if (id) {
      try {
        const { data } = await getSingleData(id);
        console.log(data.data.summary);
        setTitle(data.data.title);
        setSummary(data.data.summary)
        setTextEditoreData(data.data.description);
        setImage(data.data.images[480]);
      } catch (err) {
        console.log("error single article", err);
      }
    }
  };
  console.log(summary);

  useEffect(() => {
    getSingleArticle();
    window.scrollTo(0,0)
  }, []);

  //edit article
  const editDataHandler = async () => {
    console.log("title", title, "description", textEditoreData,'summary',summary);

    formData.append("title", title);
    formData.append("description", textEditoreData);
    formData.append("summary",summary)
console.log(summary);
    selectFile && formData.append("image", selectFile);
    try {
      await editUserArticleData({ id: id, formData });
      setRedirect(false);
    } catch (err) {
      toast.error("مشکلی پیش آمده");
    }
  };

  //post article
  const postDataHandler = async () => {
    //formData

    formData.append("title", title);
    formData.append("description", textEditoreData);
    formData.append("summary",summary)
    selectFile && formData.append("image", selectFile);

    try {
      const post = await postArticle(formData);
      console.log("post", post);
    } catch (err) {
      swal("مشکلی پیش آمده", "error");
    }
  };
  console.log("summary", summary);
  return (
    <Fragment>
      <Row className="article-form flex justify-content-center">
        <Col sm="12" xl="11" md="11" className="m-4">
          <Card className="m-2">
            <CardHeader>
              <h5>ثبت مقاله جدید</h5>
            </CardHeader>
            <CardBody>
              <Form className="theme-form">
                <FormGroup>
                  <Label className="col-form-label pt-0">موضوع مقاله</Label>
                  <Input
                    value={title}
                    className="form-control "
                    type="text"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <div className="d-flex flex-column  justify-content-center align-items-center">
                    {id &&
                      (image ? (
                        <Card className="mt-3 p-2 previous-img">
                          <Trash2 className="trash" onClick={() => {
                            setSelectFile(null)
                            setImage(null)
                          }} />
                          <CardHeader className="my-2 p-2 info">
                            عکس فعلی:
                          </CardHeader>

                          <LazyLoadImage
                            effect="blur"
                            width={"100%"}
                            src={`${BaseApi}${image}`} // use normal <img> attributes as props
                            height={"50%"}
                            placeholderSrc={localImage}
                          />
                        </Card>
                      ) : (
                        <Card className="mt-4 p-4 bg-danger">
                        شما عکسی را از قبل اضافه نکرده اید!
                        </Card>
                      ))}
                  </div>
                  <Card className="mt-2">
                    <CardHeader className="my-2 p-2 info">
                      افزودن عکس جدید:
                    </CardHeader>{" "}
                    <ImageUploader
                      type="file"
                      withIcon={true}
                      withPreview={true}
                      buttonText="انتخاب عکس"
                      label="png - gif - jpg حداکثر حجم عکس 1 مگابایت | فرمت عکس ها "
                      fileSizeError="حجم عکس بیش از حد مجاز است"
                      fileTypeError="فرمت  عکس پشتیبانی نمیشود."
                      imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                      onChange={(e) => {
                        setSelectFile(e[0]);
                      }}
                      maxFileSize={5242880}
                    />
                  </Card>
                </FormGroup>
                <FormGroup>
                  <JoditEditor
                    value={textEditoreData}
                    onChange={handleChange}
                                />
                </FormGroup>
                <FormGroup >
                 <Input type="textarea" value={summary} onChange={(e)=>setSummary(e.target.value)} maxLength={'250'} placeholder="خلاصه مقاله..." />
                </FormGroup>
              </Form>
              {redirect && <Redirect to="/user/my-articels" />}
              <Button
                color="primary"
                className="mr-1 "
                onClick={() => {
                  id
                    ? title && textEditoreData &&summary
                      ? swal({
                          title: "تغییرات اعمال شود؟",
                          text: `شما در حال تغییر مقاله با عنوان ${title} می باشید.`,
                          icon: "warning",
                          buttons: ["کنسل شود!", "بله "],

                          dangerMode: true,
                        }).then((isConfirm) => {
                          if (isConfirm) {
                            editDataHandler();
                            swal({
                              text: "تغییرات شما با موفقیت اعمال شد.لطفا تا زمان تایید مقاله منتظر بمانید.",
                              icon: "success",
                              buttons: "باشه",
                            });
                            setRedirect(true);
                          }
                        })
                      : toast.error("شما فیلد انتخاب نشده دارید")
                    : title && textEditoreData&&summary
                    ? swal({
                        title: "مقاله جدید اضافه شود؟",
                        text: `شما در حال ثبت مقاله با عنوان ${title} می باشید.`,

                        icon: "warning",
                        buttons: ["کنسل شود!", "بله "],

                        dangerMode: true,
                      }).then((isConfirm) => {
                        if (isConfirm) {
                          postDataHandler();
                          swal({
                            text: "مقاله شما با موفقیت ثبت شد.لطفا تا زمان تایید مقاله منتظر بمانید.",
                            icon: "success",
                            buttons: "باشه",
                          });

                          setRedirect(true);
                          console.log(isConfirm);
                        }
                      })
                    : toast.error("شما فیلد انتخاب نشده دارید");
                }}
              >
                ثبت
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ArticleForm;
