import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'reactstrap'
import { postApi } from '../../services/Api/api';
import { toast } from 'react-toastify';
import Loader from '../../components/Loading/Loader';
import { Link } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-v3'
import AuthCss from './../../assets/Css/Auth/Auth.module.css'
import RegisterCss from './../../assets/Css/Auth/RegisterUser.module.css'


const Register = ({ props, history }) => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [role, setRole] = useState("user");
  const [studentId, setStudentId] = useState("");

  useEffect(() => {
    loadReCaptcha("6Le6_hQeAAAAAFL9VlTOeJUKgyn0X2z7bS2nJX8K");

  }, []);

  const [togglePassword, setTogglePassword] = useState(false)
  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword)
  }

  const [showStId, setShowStId] = useState(false);

  const HandleRole = (e) => {
    if (e == "user") {
      setRole("user")
      setShowStId(false)
    }
    else if (e == "teacher") {
      setRole("teacher")
      setShowStId(false)

    }
    else if (e == "student") {
      setRole("student")
      setShowStId(true)
    }
    else if (e == "employee") {
      setRole("employee")
      setShowStId(false)
    }
  }

  const HandleStudentId = (e) => {
    setStudentId(e)
  }

  const [True, SetTrue] = useState(false)
  const CloseHandler = () => {
    SetTrue(false)
  }
  const OpenHandler = () => {
    SetTrue(true)
  }

  const RegisterHandler = async () => {

    if (name === "") {
      toast.error("لطفا نام خود را وارد کنید")
    }
    else if (email === "") {
      toast.error("لطفا ایمیل خود را وارد کنید")
    }
    else if (password === "") {
      toast.error("لطفا رمز عبور خود را وارد کنید")
    }
    else if (password.length < 6) {
      toast.error("رمز عبور باید حد اقل 6 کاراکتر باشد")
    }
    else if (mobile === "") {
      toast.error("لطفا شماره موبایل خود را وارد کنید")
    }
    else if (mobile.length !== 10) {
      toast.error("شماره موبایل حتما باید 10 رقمی باشد")
    } else if (showStId && studentId === "") {
      toast.error("لطفا 4 رقم آخر شماره دانشجویی خود را وارد کنید")
    } else if (showStId && studentId.length !== 4) {
      toast.error("لطفا در وارد کردن شماره دانشجویی دقت کنید")
    }
    else {
      OpenHandler()

      if (showStId) {
        var body = {
          name,
          email,
          password,
          mobile,
          role,
          studentId
        }
      }
      else {
        var body = {
          name,
          email,
          password,
          mobile,
          role
        }
      }
      try {
        const { status } = await postApi({
          path: "auth/register",
          body
        });
        if (status === 201) {
          toast.success("حساب کاربری با موفقیت ایجاد شد", {
            position: "top-right",
            closeOnClick: true
          });

          history.push(`/pages/auth/login`);
          CloseHandler()

        } else {
          CloseHandler()
        }
        CloseHandler()
      } catch (error) {
        toast.error(error.response.data.error, {
          position: "top-right",
          closeOnClick: true
        });
        CloseHandler()
      }
    }
  }


  return (
    <Container fluid={true} className="p-0">
      <Loader true={True} />
      <Row>
        <Col xs="12">

          <div className={`${AuthCss.Background}`}>
            {/* <Link to="/Home"><img className={`${AuthCss.backHome}`} src={require("./../../../../Assets/UserAssets/UserContent/Auth/home.png").default} alt="" /></Link> */}
            <div className={`${AuthCss.Header}`}>
              <div className={`${AuthCss.logo}`}>
                <img src={require("./../../assets/images/logo/LogoAB.png")} alt="" />
              </div>
              <p>کتابخانه مرکزی دانشگاه غالب</p>
              <div className={`${AuthCss.ContactUs}`}>
                {/* <a href=""><img src={require("./../../../../Assets/UserAssets/UserContent/Auth/facebook.png").default} alt="" /></a> */}
                {/* <a href=""><img src={require("./../../../../Assets/UserAssets/UserContent/Auth/twitter.png").default} alt="" /></a> */}
                {/* <a href=""><img src={require("./../../../../Assets/UserAssets/UserContent/Auth/linkedin.png").default} alt="" /></a> */}
              </div>
            </div>
            <div className={`${AuthCss.Content}`}>
              <div className={`${RegisterCss.register}`}>
                <h3>ایجاد جساب</h3>
                <form>
                  <div className={`${RegisterCss.input}`}>

                    <select className="form-control" name="role" value={role} onChange={e => HandleRole(e.target.value)}>
                      <option value="user" className="PublicFont" >نوع حساب (پیش فرض کاربر)</option>
                      <option value="user" className="PublicFont">کاربر</option>
                      <option value="employee" className="PublicFont">کارمند</option>
                      <option value="student" className="PublicFont">دانشجو</option>
                      <option value="teacher" className="PublicFont">مدرس</option>
                    </select>


                    <input
                      className="form-control"
                      type="text" required=""
                      placeholder="نام خود را وارد کنید"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />

                    <input
                      className="form-control"
                      type="text" required=""
                      placeholder="ایمیل خود را وارد کنید"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />

                    <input
                      className="form-control"
                      type="password"
                      placeholder='رمز عبور خود را وارد کنید'
                      name="login[password]"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />

                    <input
                      className="form-control"
                      type="text"
                      name="mobile"
                      placeholder="شماره موبایل"
                      maxLength="10"
                      required=""
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={mobile}
                      onChange={e => {
                        setMobile(e.target.value);
                      }}
                    />
                    {showStId ?
                      <input
                        className="form-control"
                        type="text" maxLength="4"
                        required=""
                        placeholder="4 رقم آخر شماره دانشجویی"
                        value={studentId}
                        onChange={e => HandleStudentId(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      /> : null}
                  </div>

                  <div className={`${RegisterCss.button}`}>
                    <button type="button" onClick={RegisterHandler}>ایجاد حساب</button>
                  </div>
                </form>
                <Link to={`/pages/auth/login`}>
                  <p>حساب خود را بیاد دارم</p>
                </Link>

                <a href={`/`} className={`${AuthCss.HomeButton}`}>
                  <Button color="success" type="button" className="btn-block" >{"صفحه اصلی"}</Button>
                </a>

              </div>
            </div>

          </div>

        </Col>
      </Row>
    </Container>
  );
}

export default Register;