import React, { Fragment } from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'

const Breadcrumbs = (props) => {
  return (
    <Fragment>
      <Container fluid={true} >
        <div className="page-title PublicFont">
          <Row>
            <div style={{height:"20px"}}></div>
            <Col xs="6">
              <h3>{props.title}</h3>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default Breadcrumbs;