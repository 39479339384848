import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import BCss from "./../../assets/Css/Banner.module.css"
import "./../../assets/Css/Banner.css"
import { Link } from 'react-router-dom';
import { getApi } from './../../services/Api/api';
import { useState, useEffect } from 'react';
import { BaseApi } from "./../../services/config.json";
import { AB_ShowMore } from '../Constant/NewConstant'

const colRow = {
    sm: {
        "1": "12",
        "2": "6",
        "3": "4",
        "4": "3"
    },
    xs: {
        "1": "12",
        "2": "12",
        "3": "12",
        "4": "12"
    },
    class: {
        "1": "Baner-1",
        "2": "Baner-2",
        "3": "Baner-3",
        "4": "Baner-4"
    }
}

const Banner = (props) => {

    const [banner, setBanner] = useState([]);

    const GetBanner = async () => {
        try {

            const result = await getApi({
                path: "/banners/show-user",
                params: { limit: 4 }
            });
            setBanner(result.data?.data)

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        GetBanner()
    }, [])


    var FilteredBanners = banner.filter(function (banner) {
        return banner.position === 1;
    });


    const setCategorySearchToLocalStorage = (item) => {
        localStorage.setItem("categoryId", item.category._id)
    }

    return (
        <Fragment>
            <Row className={BCss.Box}>

                {FilteredBanners ? FilteredBanners.map((item, index) =>
                    <Col key={index} sm={colRow.sm[FilteredBanners.length]} xs={colRow.xs[FilteredBanners.length]} onClick={() => setCategorySearchToLocalStorage(item)}>
                        <div className={`${BCss.Baner} ${colRow.class[FilteredBanners.length]}`}>
                            <img src={`${BaseApi}${item.image}`} alt="" />
                            <Link to={`/BookList`}>
                                <p className='card'>{AB_ShowMore}</p>
                            </Link>
                        </div>
                    </Col>
                ) : ""}
            </Row>
        </Fragment>

    );
}

export default Banner;