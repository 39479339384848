import React, { Fragment, useState, useEffect } from 'react';
import man from '../../assets/images/dashboard/profile.jpg'
import { FileText, LogIn, Mail, User, MessageSquare, Bell, Minimize, Search, ShoppingCart, Minus, Plus, X } from 'react-feather';
import { useHistory } from 'react-router-dom'
import { firebase_app } from '../../data/config'
import { useAuth0 } from '@auth0/auth0-react'
import Bookmark from "../../layout/bookmark"
import { Link } from 'react-router-dom'
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';

import { English, Deutsch, Español, Français, Português, 简体中文, Notification, DeliveryProcessing, OrderComplete, TicketsGenerated, DeliveryComplete, CheckAllNotification, ViewAll, MessageBox, EricaHughes, KoriThomas, Admin, Account, Inbox, Taskboard, LogOut, AinChavez, CheckOut, ShopingBag, OrderTotal, GoToShopingBag } from '../../constant'

import en from '../../assets/i18n/en.json';
import es from '../../assets/i18n/es.json';
import pt from '../../assets/i18n/pt.json';
import fr from '../../assets/i18n/fr.json';
import du from '../../assets/i18n/du.json';
import cn from '../../assets/i18n/cn.json';
import ae from '../../assets/i18n/ae.json';
import { InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { deleteApi, getApi } from './../../services/Api/api';

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage('en');
setLanguageCookie();

const Rightbar = (props) => {

  const [notification, setNotification] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [me, setMe] = useState('');

  const GetUserNotification = async () => {
    const result = await getApi({
      path: "notifications/user",
    });
    setNotification(result.data?.data);
  };

  const GetUserData = async () => {
    const GetRooms = await getApi({
      path: "rooms",
    });
    setRooms(GetRooms.data?.data);

    const GetMe = await getApi({
      path: "users/me"
    });
    setMe(GetMe.data?.data);

  }




  var FilterRooms = rooms.filter(function (item) {
    return item.newMessage.length > 0;
  });


  const logOutUser = () => {
    localStorage.clear()
    toast.success("شما خارج شدید", {
      position: "top-right",
      closeOnClick: true
    });
  }

  const history = useHistory();
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('')
  const [searchresponsive, setSearchresponsive] = useState(false)
  const [langdropdown, setLangdropdown] = useState(false)
  const [moonlight, setMoonlight] = useState(false)
  const [selected, setSelected] = useState("en")
  const [cartDropdown, setCartDropDown] = useState(false)
  const [notificationDropDown, setNotificationDropDown] = useState(false)
  const [chatDropDown, setChatDropDown] = useState(false)

  // auth0 profile
  const { logout } = useAuth0()
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"))

  const handleSetLanguage = (key) => {
    setLanguage(key);
    setSelected(key)
  };

  useEffect(() => {
    if (localStorage.getItem("jwtToken")) {
      GetUserNotification()
      GetUserData()

      setInterval(() => {
        GetUserNotification()
      }, 10000);
    }

    setProfile(localStorage.getItem('profileURL') || man);
    setName(localStorage.getItem('Name'))
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true)
    }
  }, [window.location.pathname]);

  const Logout_From_Firebase = () => {
    localStorage.removeItem('profileURL')
    localStorage.removeItem('token');
    firebase_app.auth().signOut()
    history.push(`/login`)
  }

  const Logout_From_Auth0 = () => {
    localStorage.removeItem("auth0_profile")
    localStorage.setItem("authenticated", false)
    history.push(`/login`)
    logout()
  }

  const RedirectToChats = () => {
    history.push(`/app/chat-app`)
  }

  const RedirectToCart = () => {
    history.push(`/cart`)
  }

  const UserMenuRedirect = (redirect) => {
    history.push(redirect)
  }

  //full screen function
  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const SeacrhResposive = (searchresponsive) => {
    if (searchresponsive) {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.add("open");
      document.querySelector(".more_lang").classList.remove("active");
    } else {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.remove("open");
    }
  }

  const LanguageSelection = (language) => {
    if (language) {
      setLangdropdown(!language)
    } else {
      setLangdropdown(!language)
    }
  }

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light)
      document.body.className = "light"
      localStorage.setItem('layout_version', 'light');
    } else {
      setMoonlight(!light)
      document.body.className = "dark-only"
      localStorage.setItem('layout_version', 'dark-only');
    }
  }

  const BookPage = (item) => {
    const id = item.id;
    const slug = item.slug
    history.push({
      pathname: `/BookPage/${slug}`,
      search: `?id=${id}`,
      state: { detail: item }
    });
  };


  const LoanList = () => {
    const NavePage = 3;
    localStorage.setItem("SetNavForAdminPanel", NavePage)
  }

  const Viewed = async () => {
    try {
      const { status } = await deleteApi({
        path: `notifications/seen`
      });
      if (status === 201) {
        console.log("Deleted Notif")
      }
      GetUserNotification()
    } catch (error) {
      console.log(error);
    }

  }


  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">

          {localStorage.getItem("jwtToken") ?
            <Fragment>
              <li className="onhover-dropdown PublicFont">
                <div className="notification-box" onClick={() => setNotificationDropDown(!notificationDropDown)}><Bell />
                  {notification.length ?
                    <span className="badge badge-pill badge-secondary">{notification?.length}</span>
                    : ""
                  }
                </div>
                <ul className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active" : ""}`}>
                  <li>
                    <Bell />
                    <h6 className="f-18 mb-0">اعلان ها</h6>
                  </li>


                  {notification ? notification.map((item, i) => {
                    return (
                      <Fragment>
                        {
                          item?.baseName == "books" ?
                            <li key={i} onClick={() => BookPage(item.book)}>
                              <p><i className="fa fa-circle-o mr-3 font-primary"> </i> معرفی کتاب {item.book.name}</p>
                            </li>
                            : ""
                        }
                        {
                          item?.baseName == "loans" ?
                            <li>
                              <Link to={`/MainPanel`} onClick={() => LoanList()}>
                                <p><i className="fa fa-circle-o mr-3 font-danger"> </i>{item.book.dayOfDelevery} روز مانده به تحویل کتاب {item.book.name}</p>
                              </Link>
                            </li>
                            : ""
                        }
                      </Fragment>
                    )
                  }) :
                    "no notif"
                  }
                  {notification.length ?
                    <li>
                      <Button color="success" className="m-r-10" onClick={() => Viewed()}>
                        <i className="fa fa-eye mr-1"></i>{"مشاهده شد"}
                      </Button>
                    </li>
                    :
                    <li>
                      اعلانی موجود نمی باشد
                    </li>
                  }

                  {/* <li>
                <p><i className="fa fa-circle-o mr-3 font-success"></i>{OrderComplete}<span className="pull-right">{"1 hr"}</span></p>
              </li>
              <li>
                <p><i className="fa fa-circle-o mr-3 font-info"></i>{TicketsGenerated}<span className="pull-right">{"3 hr"}</span></p>
              </li>
              */}
                </ul>
              </li>
              <li className="onhover-dropdown" onClick={() => setChatDropDown(!chatDropDown)}><MessageSquare />
                {/* <div className="notification-box" >
              <span className="badge badge-pill badge-secondary">2</span>

            </div> */}
                <ul className={`chat-dropdown onhover-show-div ${chatDropDown ? "active" : ""}`} style={{ padding: "0" }}>
                  <li style={{ width: "100%" }}>
                    <MessageSquare />
                    <h6 className="f-18 mb-0" >پیام ها</h6>
                  </li>
                  {rooms.map((item, i) => {
                    return (
                      <Fragment>
                        {
                          item.newMessage.length ?
                            <li key={i}>
                              <Link to="/Chat">
                                <div className="media"><img className="img-fluid rounded-circle mr-3" src={require("../../assets/images/Landing/user.png")} alt="" />
                                  <div className="media-body">
                                    {item.from.id == me.id ?
                                      <span className='PublicFont'>{item?.to?.name}</span>
                                      :
                                      <Fragment>
                                        {item.to.id == me.id && item.from.id == me.id ?
                                          ""
                                          : <span className='PublicFont'>{item?.from?.name}</span>
                                        }
                                      </Fragment>
                                    }
                                    <p className='PublicFont'>شما یک پیام جدید دارید</p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            : null
                        }
                      </Fragment>)
                  })
                  }
                  {FilterRooms?.length > 0 ?
                    <li className="text-center">
                      <Link to="/Chat">
                        <Button color="success" className="m-r-10">
                          <i className="fa fa-eye mr-1"></i>{"مشاهده همه"}
                        </Button>
                      </Link>
                    </li>
                    :
                    <li className='InputSetting'>
                      پیامی موجود نمی باشد
                    </li>
                  }
                </ul>
              </li>
            </Fragment>
            : ""
          }

          <Link to={`/BookList`}>
            <li><span className="header-search"><Search /></span></li>
          </Link>

          <li className="language-nav">
            <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
              <div className="current_lang">
                <div className="lang" onClick={() => LanguageSelection(langdropdown)}>
                  <i className="flag-icon flag-icon-af"></i>
                  <span className="lang-txt">{"AF"}</span>
                </div>
              </div>
              {/* <div className={`more_lang ${langdropdown ? 'active' : ''}`}>
                <div className="lang" onClick={() => handleSetLanguage('en')}><i className="flag-icon flag-icon-us"></i><span className="lang-txt">{English}<span> {"(US)"}</span></span></div>
                <div className="lang" onClick={() => handleSetLanguage('ae')}><i className="flag-icon flag-icon-ae"></i><span className="lang-txt">{"لعربية"}<span> {"(ae)"}</span></span></div>
              </div> */}
            </div>
          </li>


          {/* <Bookmark /> */}




          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div>
          </li>

          <li className="maximize"><a className="text-dark" href="#javascript" onClick={goFull}><Minimize /></a></li>





          {props.IsLogin ?
            <li className="profile-nav onhover-dropdown p-0" >
              <div className="media profile-media">
                {/* <img className="b-r-10" src={authenticated ? auth0_profile.picture : profile} alt="" /> */}
                <div className="media-body PublicFont"><span>پروفایل</span>
                  <p className="mb-0 font-roboto PublicFont">فعال <i className="middle fa fa-angle-down"></i></p>
                </div>
              </div>
              <ul className="profile-dropdown onhover-show-div" style={{ marginRight: "-30px" }}>
                <Link to={`/MainPanel`} >
                  <li><User /><span className="PublicFont">{"پنل کاربری"} </span></li>
                </Link>
                <Link to={`/pages/auth/login`}>
                  <li onClick={() => logOutUser()} ><LogIn /><span className="PublicFont">{"خروج"}</span></li>
                </Link>
              </ul>
            </li>
            : ""}
        </ul>
      </div>
    </Fragment>

  );
}
export default translate(Rightbar);