import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, FormGroup, Label, Input, Form } from 'reactstrap'
import LandingCss from './../../assets/Css/Landing.module.css'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom';
import {ab_sb, AB_Search } from '../Constant/NewConstant'
import { useLocation } from 'react-router-dom'
import RSlider from './RSlider';


const Default = ({ props, history }) => {

  const { pathname } = useLocation();

  const [search, setSearch] = useState("");
  const SearchUser = () => {
    localStorage.setItem("Usersearch", search)

  };
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem('Usersearch');
    localStorage.removeItem('categoryId')

  }, [pathname])

  
  return (
    <Fragment>

      <img className={LandingCss.VideoTage} src={require('./../../assets/images/Landing/IndexImg.jpg')} alt="" />
      <div className={LandingCss.MainSlider} >
        <Form>
          <FormGroup>
            <Label className={`${"form-label"} ${LandingCss.Titr}`}><img className={LandingCss.SearchImg} src={require('./../../assets/images/Landing/book.png')} alt="SearchImg" /> {ab_sb}</Label>
            <Input className={`${"form-control InputSetting resin"} ${LandingCss.input}`} type="text" placeholder={ab_sb} value={search} onChange={(e) => setSearch(e.target.value)} />
          </FormGroup>
          <div className="form-footer">
            <Link to={`/BookList`}>
              <button type="submit" className="btn btn-primary btn-block InputSetting resin" onClick={() => SearchUser()}>{AB_Search}</button>
            </Link>
          </div>
        </Form>
      </div>

      <RSlider />

      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">

        </Row>
      </Container>
    </Fragment>
  );
}

export default withRouter(Default);