import React, { Fragment } from 'react';
import { Card, Col, CardHeader, CardBody } from 'reactstrap';
import BPcss from './../../assets/Css/BookPage.module.css'
import { putApi } from './../../services/Api/api';
import { ab_download, ab_PdfFiles } from './../Constant/NewConstant'
import { BaseApi } from "./../../services/config.json";
import { Link } from 'react-router-dom';

const PdfFile = (props) => {


    const IncreaseDownloaded = async () => {
        try {
            const { status } = await putApi({ path: `/books/${props?.Id}/inc-downloades` });
            if (status === 200) {
                console.log("Downloaded")
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Fragment>
            {props?.File.length ?
                <div>
                    <Col xl="12 PublicFont">
                        <Card className="card-absolute">
                            <CardHeader className="bg-primary">
                                <h5>{ab_PdfFiles}
                                    <img className={BPcss.PdfTitrImg} src={require('./../././../assets/images/Landing/pdf.png')} alt="" />
                                </h5>
                            </CardHeader>
                            <CardBody style={{ paddingTop: "50px" }}>
                                {props.File.map((item, i) => {
                                    return (
                                        <Fragment key={i} >

                                            <div id="wrap" className='downloadBox'>
                                                <span className="f-18 PartTitr">{item.name}</span>
                                                {localStorage.getItem("jwtToken") ?
                                                    <a onClick={() => IncreaseDownloaded()} href={`${BaseApi}${item.path}`} target="_blank" rel="noreferrer" >
                                                        <div class="button65456">
                                                            {ab_download}
                                                            <span>
                                                                <span></span>
                                                            </span>
                                                        </div>
                                                    </a>
                                                    :
                                                    <Link to={`/pages/auth/login`}>
                                                        <div className='buttonLogeOut'>
                                                            برای دانلود لطفا وارد شوید
                                                        </div>
                                                    </Link>
                                                }
                                            </div>


                                        </Fragment>
                                    )
                                })}
                            </CardBody>
                        </Card>
                    </Col>
                </div>
                : ''}
        </Fragment>
    );
}

export default PdfFile;