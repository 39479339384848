import React, { Fragment, useState, useEffect } from "react";
import { Eye, MessageCircle } from "react-feather";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  Col,
  Media,
  Row,
} from "reactstrap";
import { getSingleData } from "../../../services/articles";
import { BaseApi } from "../../../services/config.json";
import "./style.css";
import moment from "jalali-moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import localImage from "./../../../assets/images/noimage/noimage.jpeg";
import UserAvatar from './../../../assets/images/userCM.png'
import Comment from "../Comment";
import { toast } from "react-toastify";
import Loader from "../../Loading/Loader";

const SingleArticle = () => {
  const [url, setUrl] = useState();
  const [article, setArticle] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  console.log("location", location.search.split("=")[1]);
  const params = useParams();

  //fetch article by id
  const history = useHistory();
  console.log("history", history.location.pathname.split('/')[1]);
  const id = location.search.split("=")[1];
  console.log(id);
  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await getSingleData(id);
      setArticle(data.data);
      setLoading(false);
    } catch (err) {
      toast.error("مشکلی پیش آمده");
    }
  };
  console.log(article.user?.profileImage?.minimal);
  useEffect(() => {
    fetchData();
    window.scrollTo(0,0)

  }, []);

  const jalalidate = moment(article.createdAt)
    .locale("fa")
    .format("YYYY/MM/DD");


  return (
    <Fragment>
      {loading && <Loader true={true} />}

      <Row className="m-4 w-75 d-flex flex-column justify-content-center m-auto single-article">
        <Col sm="12" className="mt-4 p-0 ">
          <Card
            style={{ maxHeight: "100%" }}
            className="m-12 single-article-card "
          >
            <CardHeader className="b-l-primary border-3 header-article-card">
              <Col>
                <Row>
                  <div className="header-article-row">
                    <div className="user-image">
                      <div className="avatar">
                       {article.user?.profileImage?.minimal? <LazyLoadImage
                          className="single-article avatar-img mb-4"
                          effect="blur"
                          width={"100px"}
                          style={{ objectFit: "fill",borderRadius:'50%' }}
                          src={`${BaseApi}${article.user?.profileImage?.minimal}`} // use normal <img> attributes as props
                          height={"100px"}
                          placeholderSrc={UserAvatar}
                        />:<img className="empty-avatar" src={UserAvatar} alt="عکس ناموجود"/> }
                      </div>
                    </div>

                    <div className="reveiw">
                      <span>{article.review}</span>
                      <span>
                      <i class="fa fa-eye"></i>
                      </span>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="header-article-row">
                    <h6 className="mb-0 mt-4">
                      <h5>{article.title}</h5>
                    </h6>
                    <span>{jalalidate}</span>
                  </div>
                </Row>
              </Col>
            </CardHeader>
            {article ? (
              <CardBody className=" p-4 single-article-card-body ArticleM">
                {article.images && (
                  <div className="card-img ">
                    {" "}
                    <LazyLoadImage
                      className="img  "
                      effect="blur"
                      src={`${BaseApi}${article?.images[720]}`} // use normal <img> attributes as props
                      placeholderSrc={localImage}
                      alt="بدون عکس"
                    />
                  </div>
                )}

                <div
                  dangerouslySetInnerHTML={{ __html: article.description }}
                />
              </CardBody>
            ) : (
              <Loader />
            )}
          </Card>
        </Col>
        <Comment id={id} />
      </Row>
    </Fragment>
  );
};

export default SingleArticle;
