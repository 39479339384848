import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, Row, Col, TabContent, PaginationItem, PaginationLink, Pagination, CardBody, TabPane, Nav, NavItem, NavLink, Button, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form } from 'reactstrap';
import { postApi } from '../../services/Api/api';
import { useEffect } from 'react';
import { getApi } from './../../services/Api/api';
import moment from 'jalali-moment'
import { BaseApi } from "./../../services/config.json";
import { ab_Next, ab_Send, ab_LoginToSendComment, ab_SendAnswer, ab_SendComment, ab_Comments, ab_Prev, ab_FirstPersonToSendComment } from './../Constant/NewConstant'
import CMcss from './../../assets/Css/Comment.module.css'

const Comment = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    const [me, setMe] = useState('');
    const [comment, setComment] = useState('');
    const [showComment, setShowComment] = useState('');
    const [book, setBook] = useState('');

    const [commentPagination, setCommentPagination] = useState([]);
    const [pageNumber, setpageNumber] = useState(1);
    const [limitComment, setLimitComment] = useState(10);

    const LoadData = async (CommentPageNumber) => {
        const result = await getApi({
            path: "comments/for-one-book",
            params: {
                book: props?.Id,
                page: CommentPageNumber,
                limit: limitComment
            }
        });
        setShowComment(result.data.data.docs);
        setCommentPagination(result.data.data);

    };

    const GetMeFunction = async () => {
        const GetMe = await getApi({
            path: "users/me"
        });
        setMe(GetMe.data?.data);
    }


    const Next = (pageNumber) => {
        let CommentPageNumber = pageNumber + 1;
        setpageNumber(CommentPageNumber)
        LoadData(CommentPageNumber)
    }
    const Prv = (pageNumber) => {
        let CommentPageNumber = pageNumber - 1;
        setpageNumber(CommentPageNumber)
        LoadData(CommentPageNumber)
    }



    useEffect(() => {
        if (localStorage.getItem("jwtToken")) {
            GetMeFunction()
        }
        LoadData()
        setBook(props?.Id);
    }, [window.location.pathname])



    const SendCm = async event => {
        event.preventDefault();
        if (comment === "") {
            toast.error("لطفا نظر خود را وارد کنید")
        }
        else {
            const body = { comment, book };
            try {
                const { status } = await postApi({
                    path: "comments",
                    body
                });
                if (status === 201) {
                    toast.success("کامنت شما با موفقیت ثبت شد", {
                        position: "top-right",
                        closeOnClick: true
                    });
                }
                setComment("")

            } catch (error) {
                console.log(error);
                toast.error("مشکل در ارسال کامنت", {
                    position: "top-right",
                    closeOnClick: true
                });
            }
        }
    };


    const [VaryingContentone, setVaryingContentone] = useState(false);
    const VaryingContentonetoggle = () => {
        setVaryingContentone(!VaryingContentone);
        setAnswer("")
    }

    const [CommentInfo, setCommentInfo] = useState("");
    const [Answer, setAnswer] = useState("");

    const AnswerComment = (item) => {
        setCommentInfo(item)
        VaryingContentonetoggle()
    }



    const SendAnswer = async event => {
        event.preventDefault();
        if (Answer === "") {
            toast.error("لطفا پاسخ خود را وارد کنید")
        }
        else {
            const body = { comment: Answer, book: props?.location?.search.slice(4), parent: CommentInfo.id };
            try {
                const { status } = await postApi({
                    path: "comments",
                    body
                });
                if (status === 201) {
                    toast.success("پاسخ شما با موفقیت ثبت شد", {
                        position: "top-right",
                        closeOnClick: true
                    });
                }
                setAnswer("")
                setVaryingContentone(false)
            } catch (error) {
                console.log(error);
                toast.error("مشکل در ارسال کامنت", {
                    position: "top-right",
                    closeOnClick: true
                });
            }
        }
    };


    return (
        <Fragment>
            <Card >
                <Row className="m-4 product-page-main m-0 PublicFont">
                    <Col sm="12">
                        <Nav tabs className={`border-tab ${CMcss.Paginatebox}`} style={{ position: "relative" }}>
                            <NavItem id="myTab" role="tablist">
                                <NavLink href="#" className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                    {ab_SendComment}
                                </NavLink>
                                <div className="material-border"></div>
                            </NavItem>
                            <NavItem id="myTab" role="tablist">
                                <NavLink href="#" className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                    {ab_Comments} {`(${props?.CommentsCount})`}
                                </NavLink>
                                <div className="material-border"></div>
                            </NavItem>




                            <CardBody className={activeTab === '1' ? `NotTrue` : ''}>
                                <Pagination aria-label="..." style={{ float: "left" }} className={CMcss.Paginate}>
                                    <ul className="pagination pagination-primary">
                                        {commentPagination.page == 1 ? null :
                                            <PaginationItem ><PaginationLink onClick={() => Prv(pageNumber)}>{ab_Prev}</PaginationLink></PaginationItem>
                                        }
                                        <PaginationLink style={{ width: "100px" }} >
                                            <span>{commentPagination.page}</span> &nbsp; / &nbsp;
                                            <span>{commentPagination.pages}</span>
                                        </PaginationLink>


                                        <Fragment>
                                            {commentPagination.page == commentPagination.pages ? null :
                                                <PaginationItem><PaginationLink onClick={() => Next(pageNumber)}>{ab_Next}</PaginationLink></PaginationItem>
                                            }
                                        </Fragment>
                                    </ul>
                                </Pagination>
                            </CardBody>

                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                {localStorage.getItem('jwtToken') ?
                                    <Form onSubmit={e => SendCm(e)}>
                                        <Col sm="12">
                                            <textarea className="form-control" rows="5" cols="5" placeholder="متن نظر"
                                                value={comment}
                                                onChange={e => setComment(e.target.value)}
                                            />
                                            <CardFooter>
                                                <Button type="submit" className="btn-lg" color="primary">{ab_Send}</Button>
                                            </CardFooter>
                                        </Col>
                                    </Form>
                                    : <Col sm="12">
                                        {ab_LoginToSendComment}
                                    </Col>
                                }
                            </TabPane>

                            <TabPane tabId="2">
                                {showComment.length ? showComment.map((item, i) =>

                                    <div className={CMcss.Comment}>
                                        <div className={CMcss.UserCm}>
                                            <div className={`${CMcss.CmBox}`}>
                                                <div className={CMcss.Cmtop}>
                                                    <h3>{item.user.name}</h3>
                                                    {localStorage.getItem('jwtToken') ?
                                                        <Fragment>
                                                            {item.user._id !== me._id ?
                                                                <p onClick={() => AnswerComment(item)}>
                                                                    <i className="fa fa-mail-reply-all"></i>{ab_SendAnswer}
                                                                </p>
                                                                : ""}
                                                        </Fragment>
                                                        : ""}
                                                    <time>
                                                        <i className="fa fa-calendar"></i>&nbsp;
                                                        {moment(item?.createdAt, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD') || ""}
                                                    </time>
                                                </div>
                                                <div className={CMcss.CmBottom}>
                                                    <p>{item.comment}</p>
                                                </div>
                                            </div>
                                            {/* <Media className="align-self-center" src={item.user?.profileImage ? `${BaseApi}${item.user?.profileImage.minimal}` : require('./../../assets/images/Landing/user.png')} alt="" /> */}
                                            <img src={item.user?.profileImage ? `${BaseApi}${item.user?.profileImage.minimal}` : require('./../../assets/images/Landing/userCM.png')} alt="" />
                                        </div>
                                        {item.childes ? item.childes.map((items, i) =>
                                            <div className={CMcss.Ans}>
                                                <div className={`${CMcss.AnsBox}`}>
                                                    <div className={CMcss.Anstop}>
                                                        <h3>{items.user.name}</h3>
                                                        <time>
                                                            <i className="fa fa-calendar"></i>&nbsp;
                                                            {moment(items?.createdAt, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD') || ""}
                                                        </time>
                                                    </div>
                                                    <div className={CMcss.AnsBottom}>
                                                        <p>{items.comment}</p>
                                                    </div>
                                                </div>
                                                <img src={items.user?.profileImage ? `${BaseApi}${items.user?.profileImage.minimal}` : require('./../../assets/images/Landing/userCM.png')} alt="" />
                                            </div>
                                        ) : ''}

                                    </div>
                                ) :
                                    <Row>
                                        <Col>
                                            <p className="NoResult">{ab_FirstPersonToSendComment}</p>
                                        </Col>
                                    </Row>
                                }

                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </Card>


            <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle}>
                <ModalHeader toggle={VaryingContentonetoggle}>
                    پاسخ به   {CommentInfo.user?.name}
                </ModalHeader>
                <ModalBody>
                    <p className='PublicFont'>{CommentInfo.comment}</p>
                </ModalBody>
                <ModalBody>
                    <Form onSubmit={e => SendAnswer(e)}>
                        <FormGroup>
                            <Label className="col-form-label" for="message-text">{"متن پاسخ : "}</Label>
                            <Input type="textarea" className="form-control PublicFont" id="message-text" placeholder="متن پاسخ"
                                value={Answer}
                                onChange={e => setAnswer(e.target.value)}

                            ></Input>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={VaryingContentonetoggle}>{"بستن"}</Button>
                    <Button color="primary" type="submit" onClick={(event) => SendAnswer(event)} >ارسال پاسخ</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default Comment;