import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col } from "reactstrap";
import "./mobileNav.css";
// import { InitialContext } from "./../../context/InitialContext";

const MobileNav = () => {
  // const { moonLight } = useContext(InitialContext);
  const [activeLink, setActiveLink] = useState({
    active1: false,
    active2: false,
    active3: true,
    active4: false,
    active5: false,
  });
  const location = useLocation();
  console.log(activeLink);
  // console.log(moonLight);
  useEffect(() => {
    switch (location.pathname) {
      case "/Chat":
        return setActiveLink({ activeLink, active1: true, active3: false });
      case "/FavoritesBook":
        return setActiveLink({ activeLink, active2: true, active3: false });
      case "/":
        return setActiveLink({ activeLink, active3: true });
      case "/BookList":
        return setActiveLink({ activeLink, active4: true , active3: false});
      case "/MainPanel":
        return setActiveLink({ activeLink, active5: true, active3: false });
      default   :
      return setActiveLink({ activeLink, active3: true });

    }
  }, [location.pathname]);
  return (
    <>
      <Col className="d-block d-sm-none p-0 mx-0 mt-4">
        <div className="navigat flex justify-content-center">
          <ul>
            <li
              className={`list ${activeLink.active1 ? "active" : "remove"}`}
              onClick={() => {
                setActiveLink({ activeLink, active1: true, active3: false });
              }}
            >
              <Link to="/Chat">
                <span className="icon">
                  <i class="fa fa-comment"></i>{" "}
                </span>
                <span className="text">پیام</span>
              </Link>
            </li>
            <li
              className={`list ${activeLink.active2 ? "active" : "remove"}`}
              onClick={() => {
                setActiveLink({ activeLink, active2: true, active3: false });
              }}
            >
              <Link to="/FavoritesBook">
                <span className="icon">
                  <i className="fa fa-heart" />
                </span>
                <span className="text">علاقه مندی ها</span>
              </Link>
            </li>
            <li
              className={`list ${activeLink.active3 ? "active" : "remove"}`}
              onClick={() => {
                setActiveLink({ activeLink, active3: true });
              }}
            >
              <Link to="/">
                <span className="icon">
                  <i className="fa fa-home" />
                </span>
                <span className="text">خانه</span>
              </Link>
            </li>
            <li
              className={`list ${activeLink.active4 ? "active" : "remove"}`}
              onClick={() => {
                setActiveLink({ activeLink, active4: true, active3: false });
              }}
            >
              <Link to="/BookList">
                <span className="icon">
                  <i className="fa fa-search" />
                </span>
                <span className="text">جستجو</span>
              </Link>
            </li>
            <li
              className={`list ${activeLink.active5 ? "active" : "remove"}`}
              onClick={() => {
                setActiveLink({ activeLink, active5: true, active3: false });
              }}
            >
              <Link to="/MainPanel">
                <span className="icon">
                  <i className="fa fa-user"></i>{" "}
                </span>
                <span className="text">پروفایل</span>
              </Link>
            </li>
            {/* <div className={`hover ${!moonLight && "dark-mode"}`}></div> */}
          </ul>
        </div>
      </Col>
    </>
  );
};

export default MobileNav;