import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { getApi, putApi } from './../../services/Api/api';
import FormData from 'form-data'
import { toast } from 'react-toastify';
import { ab_UploadImageSucsesfuly, ab_ProblemInUploadImage, ab_YourInformationEditedSucsesfuly, ab_role, ab_phoneNumber, abUpdateProfile, ab_MyProfile, ab_name, ab_takhalos, ab_DeActive, ab_ProfileImage, ab_UploadImage, ab_EditProfile, ab_active } from './../Constant/NewConstant'
import Loader from './../Loading/Loader';
import { BaseApi } from "./../../services/config.json";


const Profile = (props) => {

  const [data, setData] = useState([])

  const LoadData = async () => {
    const result = await getApi({ path: "users/me" });
    setData(result.data.data);
    console.log(result.data.data?.profileImage?.minimal)
  };
  console.log(data)

  useEffect(() => {
    LoadData()
  }, [])

  const [file, setFile] = useState()

  const UploadImg = async event => {
    event.preventDefault();
    const body = new FormData();
    body.append("avatar", file);
    try {
      const { status, data } = await putApi({
        path: "users/upload/avatar",
        body
      });
      if (status === 200) {
        toast.success(ab_UploadImageSucsesfuly, {
          position: "top-right",
          closeOnClick: true
        });
      }
      LoadData()
    } catch (ex) {
      console.log(ex);
      toast.error(ab_ProblemInUploadImage, {
        position: "top-right",
        closeOnClick: true
      });
    }
  }


  const Property = (key, e) => {
    switch (key) {
      case "name":
        setData({ ...data, name: e.target.value })
        break;
      case "college":
        setData({ ...data, college: e.target.value })
        break;
      case "takhalos":
        setData({ ...data, takhalos: e.target.value })
        break;
    }
  }

  const handleSubmit = async event => {
    OpenHandler()
    event.preventDefault();
    const body = {
      name: data.name,
      takhalos: data.takhalos,
      college: data.college
    }
    try {
      const { status } = await putApi({
        path: "users",
        body
      });
      if (status === 200) {
        toast.success(ab_YourInformationEditedSucsesfuly, {
          position: "top-right",
          closeOnClick: true
        });
      }
      CloseHandler()
    } catch (error) {
      toast.error(error.response.data.error, {
        position: "top-right",
        closeOnClick: true
      });
      console.log(error);
      CloseHandler()
    }
  };

  const [True, SetTrue] = useState(false)
  const CloseHandler = () => {
    SetTrue(false)
  }
  const OpenHandler = () => {
    SetTrue(true)
  }

  return (
    <Fragment>
      <Loader true={True} />
      <Container fluid={true} className="PublicFont">
        <div className="edit-profile">
          <Row>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{ab_MyProfile}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={e => UploadImg(e)}>
                    <Row className="mb-2">
                      <div className="col-auto">
                        <Media className="img-70 rounded-circle" alt="" src={data.profileImage ? `${BaseApi}${data?.profileImage?.minimal}` : require("../../assets/images/Landing/user.png")} />
                      </div>
                      <Col>
                        <h3 className="mb-1">{data.name}</h3>
                        <p className="mb-4">{data.status = "active" ? ab_active : ab_DeActive}</p>
                      </Col>
                    </Row>
                    <Col>
                      <Row>
                        <Col>
                          <FormGroup className="row">
                            <Label className="col-sm-12 col-form-label">{ab_ProfileImage}</Label>
                            <Col sm="12">
                              <Input className="form-control" type="file" onChange={e => setFile(e.target.files[0])} />
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <CardFooter className="text-right">
                          <button className="btn btn-primary" type="submit">{ab_UploadImage}</button>
                        </CardFooter>
                      </Row>
                    </Col>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <Form className="card" onSubmit={e => handleSubmit(e)}>
                <CardHeader>
                  <h4 className="card-title mb-0">{ab_EditProfile}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-label">{ab_name}</Label>
                          <Input className="form-control InputSetting" type="text" placeholder={ab_name} value={data.name} onChange={e => Property("name", e)} />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-label">{ab_takhalos}</Label>
                          <Input className="form-control InputSetting" type="text" placeholder={ab_takhalos} value={data.takhalos} onChange={e => Property("takhalos", e)} />
                        </FormGroup>
                      </Col>
                      {data.role == "user" ?
                        "" :
                        <Col md="12">
                          <FormGroup>
                            <Label className="form-label">{"دانشکده"}</Label>
                            <Input className="form-control InputSetting" type="text" placeholder={"دانشکده"} value={data?.college} onChange={e => Property("college", e)} />
                          </FormGroup>
                        </Col>
                      }
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label className="form-label">{"ایمیل"}</Label>
                        <Input disabled className="form-control InputSetting" type="text" placeholder={"ایمیل"} value={data.email} />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label className="form-label">{ab_role}</Label>
                        <Input disabled className="form-control InputSetting" type="text" placeholder={ab_role} value={data.role} />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup>
                        <Label className="form-label">{ab_phoneNumber}</Label>
                        <Input disabled className="form-control InputSetting" type="text" placeholder={ab_phoneNumber} value={data.mobile} />
                      </FormGroup>
                    </Col>

                  </Row>
                </CardBody>
                <CardFooter className="text-right">
                  <button className="btn btn-primary" type="submit">{abUpdateProfile}</button>
                </CardFooter>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default Profile;