import React, { Fragment, useEffect } from 'react'
import ReactDOM from 'react-dom';
import './index.scss';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store'
import App from './components/app'
import { configureFakeBackend} from './services/fack.backend'


import { ToastContainer } from 'react-toastify'

// Authentication
import Login from "./pages/authentication/login"
import Register from "./pages/authentication/register"


import Callback from './auth/callback'

import './index.css';
import './assets/Css/Font.css'
import BookList from './components/Book/BookList';
import BookPage from './components/Book/BookPage';
import Default from './components/dashboard/default'
import MainPanel from './components/UserPanel/MainPanel';
import UserChat from './components/UserChat/UserChat';

import './assets/Css/ReactToastify.css';
import FavoritesBook from './components/Book/FavoritesBook';
import ContactUs from './components/ContactUs/ContactUs';
import UserMessage from './components/UserChat/UserMessage';
import Forgetpwd from './pages/authentication/forgetpwd';




import ShowArticle from "./components/article/ShowAllArticle";
import UserArticlesTable from "./components/article/UserArticlesTable";
import ArticleForm from "./components/article/ArticleForm";
import SingleArticle from './components/article/SingleArticle';

// setup fake backend
configureFakeBackend();


const Root = (props) => {

  useEffect(() => {

  }, [window.location.pathname]);



  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter basename={`/`}>
          <Switch>


            <Route path={`/pages/auth/login`} component={Login} />

            <Route path={`/pages/auth/signup`} component={Register} />

            <Route path={`/pages/auth/ForgetPassword`} component={Forgetpwd} />

            <Route path={`/callback`} render={() => <Callback />} />


            <App>
              <Route exact path={`/BookList`} component={BookList}></Route>
              <Route exact path={`/FavoritesBook`} component={FavoritesBook}></Route>
              <Route exact path={`/ContactUs`} component={ContactUs}></Route>
              <Route exact path={`/BookPage/:id`} component={BookPage}></Route>
              <Route
                exact
                path={`/articels`}
                component={ShowArticle}
              ></Route>
              <Route
                exact
                path={`/user/add-articel`}
                component={ArticleForm}
              ></Route>
                <Route
                  exact
                  path={`/user/my-articels`}
                  component={UserArticlesTable}
                ></Route>
              <Route
                exact
                path={`/user/edit-articel/:title`}
                component={ArticleForm}
              ></Route>
                              <Route exact path={`/single-articel/:title`} component={SingleArticle}/>

              <Route exact path={`/`} component={Default}></Route>

              <Route exact path={`/`} render={() => {
                return (<Redirect to={`/`} />)
              }} />

              <Route exact path={`/Chat`} render={() => {
                return (localStorage.getItem('jwtToken') ? <UserChat /> : <Redirect to={`/pages/auth/login/`} />)
              }} />

              <Route exact path={`/UserMessage/:id`} render={() => {
                return (localStorage.getItem('jwtToken') ? <UserMessage /> : <Redirect to={`/pages/auth/login/`} />)
              }} />


              <Route exact path={`/MainPanel`} render={() => {
                return (localStorage.getItem('jwtToken') ? <MainPanel /> : <Redirect to={`/pages/auth/login/`} />)
              }} />


            </App>

          </Switch>
      <ToastContainer/>
        </BrowserRouter>
      </Provider>
    </Fragment>
  )
}

ReactDOM.render(<Root />,
  document.getElementById('root')
);
serviceWorker.register();
