import httpService from "./httpService";
import { BaseUrl } from "./config.json";
//get articles
export const getArticles = ({path,params}) => {
        console.log('get articles',path, params);
        return httpService.GET(`${path}/search`, {params:{ ...params,limit:12,select:"title summary review images"}})
    }
    //post article
export const postArticle = (body) => {
    console.log('body', body);
    return httpService.POST(`/articels?`, body)
}

//get  user articles
export const getUserArticles = (params) => {
    console.log('params', params);
    return httpService.GET(`/articels/me?`, { params:{...params,select:"title status review createdAt id"} });
};

//remove single user article
export const deleteUserArticle = (id) => {
    console.log('deleteeeeeeeeeeeeee single', id);
    return httpService.DELETE(`/articels/${id}`);
};

//get single user data 
export const getSingleData = (id) => {
    return httpService.GET(`/articels/${id}`)
}

//put article user data
export const editUserArticleData = ({ id, formData }) => {
    return httpService.PUT(`/articels/${id}`, formData)
}

//get all comments
export const getAllCommentsApi = async({ params }) => {
    console.log("getApi  : ", params);
    return await httpService.GET(`/comments/for-one-articel`, { params });
};

//post comments
export const postCommentApi = async({ body }) => {
    console.log("postApi  : ", body);
    return await httpService.POST(`/comments`, body);
};
//put comments
export const putCommentApi = async({ path, body }) => {
    console.log("postApi  : ", path, body);
    return await httpService.PUT(`/`, body);
};