import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import VerticalTimelineComp from './verticalTimelineComp';
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react';

const ContactUs = () => {
    const { pathname } = useLocation();

    const [expanded1, setexpanded1] = useState(true)
    const [expanded2, setexpanded2] = useState(false)
    const [expanded3, setexpanded3] = useState(false)
    const Accordion1 = () => {
        setexpanded1(!expanded1)
        setexpanded2(false)
        setexpanded3(false)
    }
    const Accordion2 = () => {
        setexpanded2(!expanded2)
        setexpanded1(false)
        setexpanded3(false)
    }
    const Accordion3 = () => {
        setexpanded3(!expanded3)
        setexpanded2(false)
        setexpanded1(false)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])

    return (
        <Fragment>
            <Breadcrumb parent="Bonus Ui" title="درباره من" />
            <Container fluid={true}>
                <Row>

                    <Col sm="12" xl="12">
                        <Card style={{ borderRadius: "15px" }}>
                            <CardHeader className="bg-primary" style={{ borderRadius: "15px 15px 0 0" }}>
                                <h5 className='PublicFont'>درباره من</h5>
                            </CardHeader>
                            <CardBody>
                                <Fragment>
                                    <Col sm="12">
                                        <Card>
                                            <CardHeader>
                                                <p className="mb-0 PublicFont SokhanZiba">
                                                    گاهی کسانی فراموش می کنند که انسان هستند، و فراموش می کنند که خدا شاهد و ناظر همه چیز است!                                                    <br />
                                                    هیچ چیز زیباتر از انسان شرافتمند نیست                                                </p>
                                                <div className='AboutImg'>
                                                    <img src={require('./../../assets/images/Landing/omidShayan-min.jpg')} alt="" />
                                                </div>
                                            </CardHeader>

                                            <CardBody style={{ padding: "0" }}>
                                                <VerticalTimelineComp />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Fragment>
                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </Container>


        </Fragment >
    );
};

export default ContactUs;