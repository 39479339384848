import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Badge,
  ButtonGroup,
  Button,
} from "reactstrap";
import Pagination from "../../Pagination/Pagination";
import { deleteUserArticle, getUserArticles } from "../../../services/articles";
import { Edit2, Eye, Trash2 } from "react-feather";
import { useHistory, useLocation } from "react-router-dom";
import moment from "jalali-moment";
import swal from "sweetalert";
import Loader from "../../Loading/Loader";
import "./style.css";
const UserArticlesTable = () => {
  const [data, setData] = useState();
  const [dataArray, setDataArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ status: "", page: "1" });
  const history = useHistory();
  const location = useLocation();
  console.log("location pathName", location);

  const tableColumns = [
    {
      name: "موضوع",
      selector: "title",
      center: true,
    },
    {
      name: "وضعیت",
      selector: "status",
      center: true,
    },

    {
      name: "بازدید",
      selector: "Review",
      center: true,
    },
    {
      name: "زمان ساخت",
      selector: "create_on",
      center: true,
    },

    {
      name: "کامنت",
      selector: "comments",
      center: true,
    },
    {
      name: "نمایش",
      selector: "show",
      center: true,
    },
    {
      name: "ویرایش",
      selector: "edit",
      center: true,
    },
    {
      name: "حذف",
      selector: "delete",
      center: true,
    },
  ];

  const FetchData = async (params) => {
    try {
      setLoading(true);
      const { data } = await getUserArticles(params);
      console.log("mainData", data.data);
      setDataArray(data.data.docs);
      setData(data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  //edit article query route
  const editArticleHandler = (article) => {
    const titleSlug = article.title.split(" ").join("-");
    history.push({
      pathname: `/user/edit-articel/${titleSlug}`,
      search: `?id=${article.id}`,
    });
  };
  //get user-articles
  useEffect(() => {
    FetchData();
    window.scrollTo(0, 0);
  }, [filter]);
  //when route redirected to this component changed
  useEffect(() => {
    FetchData();
    console.log("refresh");
  }, [location.pathname]);
  console.log("totalData", data);
  //show single article
  const showSingleArticle = (article) => {
    const titleSlug = article.title.split(" ").join("-");
    history.push({
      pathname: `/single-articel/${titleSlug}`,
      search: `?id=${article.id}`,
      state: { detail: article },
    });
  };

  //delete single article
  const deleteArticleHandler = async (id) => {
    console.log("clicked");
    console.log("article id", id);
    try {
      const deleteData = await deleteUserArticle(id);
      console.log("remove single article", deleteData);
      console.log("mainData", data);
      data.docs.length === 1
        ? filterChangeHandler({ ...filter, page: data.page - 1 })
        : filterChangeHandler({ ...filter });
    } catch (err) {
      console.log("deleteError", err);
    }
  };
  //show  modal after click on remove button
  console.log("dataArray", dataArray);
  //map on articleData for table
  const dataTable = [];
  dataArray.map((item, index) => {
    return dataTable.push({
      key: index,
      id: index + 1,
      title: item.title,
      status: (
        <Badge
          color={
            item.status === "pending"
              ? "warning"
              : item.status === "active"
              ? "success"
              : "danger"
          }
        >
          {item.status === "active"
            ? "فعال"
            : item.status === "pending"
            ? "در انتظار تایید"
            : "غیرفعال"}
        </Badge>
      ),
      Review: item.review,

      create_on: moment(item.createdAt, "YYYY/MM/DD")
        .locale("fa")
        .format("YYYY/MM/DD"),

      comments: item.commentsCount,

      show: (
        <span
          className="my-article-icons"
          onClick={() => showSingleArticle(item)}
        >
          <i className="fa fa-eye "></i>{" "}
        </span>
      ),
      edit: (
        <span
          onClick={() => editArticleHandler(item)}
          className="my-article-icons"
        >
          <i className="fa fa-pencil"></i>{" "}
        </span>
      ),
      delete: (
        <i
          className="fa fa-trash my-article-icons"
          onClick={() => {
            swal({
              title: "آیا مطمئنید؟",
              text: `شما در حال حذف  ${item.title} میباشید`,
              icon: "warning",
              buttons: ["کنسل شود!", "بله, حذف شود "],
              dangerMode: true,
            }).then((isConfirm) => {
              if (isConfirm) {
                deleteArticleHandler(item.id);
              }
            });
          }}
          size="16px"
        />
      ),
    });
  });

  //pagination

  //status filter
  const filterChangeHandler = (newParams) => {
    setFilter({ ...filter, ...newParams });
    const allfilter = {
      ...filter,
      ...newParams,
    };
    console.log("filters", allfilter);
    FetchData(allfilter);
  };
  return (
    <Fragment>
      <Container className="article-table">
        {loading && <Loader true={true} />}
        <Row>
          <Col sm="12">
            <Card className="m-12 article-card">
              <CardHeader>
                <h5>مقاله های من</h5>
              </CardHeader>
              <CardBody>
                <Row xl="4" sm="12 xl-50">
                  <Col className="mb-4">
                    <Badge color="primary">فیلترها</Badge>
                  </Col>
                </Row>
                <Col xl="4" md="6" sm="12 xl-50">
                  <ButtonGroup
                  className="button-group-articles"
                    onClick={(e) =>
                      filterChangeHandler({
                        status: e.target.value,
                        page: 1,
                        createdAt: "",
                      })
                    }
                  >
                    <Button color="primary" disabled={true}>
                      وضعیت
                    </Button>
                    <Button color="primary" value="">
                      همه
                    </Button>
                    <Button color="primary" value="active">
                      فعال
                    </Button>
                    <Button color="primary" value="pending">
                      انتظار
                    </Button>
                    <Button color="primary" value="inactive">
                      غیرفعال
                    </Button>
                  </ButtonGroup>
                </Col>
                <Col xl="4" md="6" sm="12 xl-50" className="mt-4 mb-4">
                  <ButtonGroup
                  className="button-group-articles"

                    onClick={(e) =>
                      filterChangeHandler({
                        status: "",
                        page: 1,
                        createdAt: e.target.value,
                      })
                    }
                  >
                    <Button color="primary" disabled={true}>
                      زمان{" "}
                    </Button>
                    <Button color="primary" value="">
                      همه
                    </Button>
                    <Button color="primary" value="-1">
                      جدیدترین
                    </Button>
                    <Button color="primary" value="1">
                      قدیمی ترین
                    </Button>
                  </ButtonGroup>
                </Col>
                {
                  <DataTable
                    data={dataTable}
                    columns={tableColumns}
                    striped={true}
                    center={true}
                    persistTableHead
                    noDataComponent={`${
                      data
                        ? "هیچ مقاله ای جهت نمایش وجود ندارد."
                        : "در حال دریافت مقالات"
                    }`}
                  />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        {data && (
          <Pagination
            totalData={data.total}
            currentPage={data.page}
            perPage={data.limit}
            onPageChange={(page) => filterChangeHandler({ page })}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default UserArticlesTable;
