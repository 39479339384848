import React, { useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postApi } from '../../services/Api/api';
import Loader from './../../components/Loading/Loader';
import { loadReCaptcha } from 'react-recaptcha-v3'
import AuthCss from './../../assets/Css/Auth/Auth.module.css'
import ForgetCss from './../../assets/Css/Auth/ForgetPassword.module.css'

const Forgetpwd = ({ props, history }) => {

  const [togglePassword, setTogglePassword] = useState(false)
  const [show, setShow] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordR, setPasswordR] = useState("")
  const [code, setCode] = useState("")

  const handleChange = (e) => {
    setPassword(e.target.value)
  }

  const handleChangeR = (e) => {
    setPasswordR(e.target.value)
  }

  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword)
  }

  useEffect(() => {
    loadReCaptcha("6Le6_hQeAAAAAFL9VlTOeJUKgyn0X2z7bS2nJX8K");

  }, []);

  const ForgetHandler = async () => {
    if (email === "") {
      toast.error("لطفا ایمیل خود را وارد کنید")
    }
    else {
      OpenHandler()
      const body = { email };
      try {
        const { status, data } = await postApi({
          path: "auth/generateCodeForResetPass",
          body
        });
        if (status == 200) {
          toast.success("کد 5 رقمی به ایمیل  شما ارسال شد", {
            position: "top-right",
            closeOnClick: true
          });
        }
        CloseHandler()
        setShow(true)
      } catch (error) {
        toast.error(error.response.data.error, {
          position: "top-right",
          closeOnClick: true
        });
        CloseHandler()
      }
    }
  }

  const ChangeHandler = async () => {
    if (code === "") {
      toast.error("لطفا کد 5 رقمی را وارد کنید")
    } else if (code.length < 5) {
      toast.error("کد ارسال شده 5 رقمی میباشد ، لطفا با دقت وارد کنید")
    } else if (password === "") {
      toast.error("لطفا رمز عبور خود را وارد کنید")
    }
    else if (password.length < 6) {
      toast.error("رمز عبور حد اقل باید 6 کاراکتر باشد")
    } else if (passwordR === "") {
      toast.error("لطفا رمز عبور خود را مجدد وارد کنید")
    }
    else if (password !== passwordR) {
      toast.error("رمز های عبور وارد شده با هم یکسان نیستند .")
    }
    else {
      OpenHandler()
      const body = { email, code, password };
      try {
        const { status, data } = await postApi({
          path: "auth/changePassword",
          body
        });
        if (status == 200) {
          toast.success("تغییر رمز عبور با موفقیت انجام شد", {
            position: "top-right",
            closeOnClick: true
          });
          history.push(`/pages/auth/login`);
        }
        CloseHandler()
      } catch (error) {
        toast.error(error.response.data.error, {
          position: "top-right",
          closeOnClick: true
        });
        CloseHandler()
      }
    }
  }


  const [True, SetTrue] = useState(false)
  const CloseHandler = () => {
    SetTrue(false)
  }
  const OpenHandler = () => {
    SetTrue(true)
  }

  const ReSendHandler = () => {
    setEmail("")
    setPassword("")
    setPasswordR("")
    setCode("")
    setShow(false)
  }


  return (
    <Container fluid={true} style={{ direction: "rtl" }}>
      <Loader true={True} />

      <Row>
        <Col xs="12">

          <div className={`${AuthCss.Background}`}>
            {/* <Link to="/Home"><img className={`${AuthCss.backHome}`} src={require("./../../../../Assets/UserAssets/UserContent/Auth/home.png").default} alt="" /></Link> */}
            <div className={`${AuthCss.Header}`}>
              <div className={`${AuthCss.logo}`}>
                <img src={require("./../../assets/images/logo/LogoAB.png")} alt="" />
              </div>
              <p>کتابخانه مرکزی دانشگاه غالب</p>
              <div className={`${AuthCss.ContactUs}`}>
                {/* <a href=""><img src={require("./../../../../Assets/UserAssets/UserContent/Auth/facebook.png").default} alt="" /></a>
                <a href=""><img src={require("./../../../../Assets/UserAssets/UserContent/Auth/twitter.png").default} alt="" /></a>
                <a href=""><img src={require("./../../../../Assets/UserAssets/UserContent/Auth/linkedin.png").default} alt="" /></a> */}
              </div>
            </div>
            <form>
              <div className={`${AuthCss.Content}`}>
                <div className={`${ForgetCss.ForgetPassword}`}>
                  <h3>فراموشی رمز عبور</h3>
                  <div className={`${ForgetCss.input}`}>
                    <input
                      disabled={show ? "disabled" : ""}
                      className="form-control"
                      type="email"
                      placeholder='Example@Mail.com'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className={show ? `NotTrue ${ForgetCss.button}` : `${ForgetCss.button}`}>
                    <button type="button" onClick={ForgetHandler}>ارسال</button>
                  </div>


                  <div className={`${ForgetCss.ResetPass}`}>
                    <Form
                      className={show ? "" : "NotTrue"}
                    >
                      <div className="text-center mt-4 mb-4 PublicFont"><span className="reset-password-link">آیا کد را دریافت نکردید  <a className="btn-link text-danger" style={{ cursor: "pointer" }} onClick={ForgetHandler} >ارسال مجدد</a></span></div>
                      <FormGroup>
                        <Label className="col-form-label pt-0 PublicFont">کد را وارد کنید</Label>
                        <Row>
                          <Col>
                            <Input className="form-control text-center opt-text InputSetting" type="text" placeholder="12345" maxLength="5"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              value={code}
                              onChange={(e) => setCode(e.target.value)}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <Col className='m-t-30'>
                        <h6 className="mt-4 InputSetting">رمز عبور جدید خود را بسازید</h6>
                      </Col>
                      <FormGroup>
                        <Label className="col-form-label PublicFont">رمز عبور جدید</Label>
                        <Input className="form-control InputSetting" type='password' name="login[password]" value={password} onChange={(e) => handleChange(e)} required="" placeholder="*********" />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label PublicFont">تکرار رمز عبور</Label>
                        <Input className="form-control InputSetting" type='password' name="login[password]" value={passwordR} onChange={(e) => handleChangeR(e)} required="" placeholder="*********" />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Button color="primary" onClick={ChangeHandler} className="btn-block InputSetting" type="button">انجام شد</Button>
                      </FormGroup>
                    </Form>

                    <div className={show ? "" : "NotTrue"}>
                      <p className="mt-4 mb-0 PublicFont" style={{ cursor: "pointer" }}>ایمیل را اشتباه وارد کرده اید ؟
                        <a className="ml-2" onClick={ReSendHandler}>تغییر ایمیل</a>
                      </p>
                    </div>

                  </div>


                  <Link to={`/pages/auth/login`}><p>رمز عبور خود را بیاد آورده اید؟ ورود</p></Link>
                </div>
              </div>
            </form>
          </div>

        </Col>
      </Row>
    </Container>
  );
}

export default Forgetpwd;