import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'reactstrap'
import { toast } from 'react-toastify';
import { postApi } from '../../services/Api/api';
import Loader from '../../components/Loading/Loader';
import { Link } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-v3'
import AuthCss from './../../assets/Css/Auth/Auth.module.css'
import LoginCss from './../../assets/Css/Auth/LoginUser.module.css'



const Logins = ({ props, history }) => {

  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState("true");

  const [togglePassword, setTogglePassword] = useState(false)

  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword)
  }

  useEffect(() => {
    loadReCaptcha("6Le6_hQeAAAAAFL9VlTOeJUKgyn0X2z7bS2nJX8K");
  }, []);


  const LoginHandler = async () => {

    if (mobile === "") {
      toast.error("لطفا شماره موبایل را وارد کنید")
    }
    else if (mobile.length !== 10) {
      toast.error("شماره موبایل حتما باید 10 رقمی باشد")
    }
    else if (password === "") {
      toast.error("لطفا رمز ورود خود را وارد کنید")
    }
    else if (password.length < 6) {
      toast.error("رمز عبور حد اقل باید 6 کاراکتر باشد")
    }
    else {
      const body = { mobile, password, rememberMe };
      try {
        OpenHandler()
        const { status, data } = await postApi({
          path: "auth/login",
          body
        });
        if (status == 200) {
          toast.success("شما با موفقیت وارد شدید", {
            position: "top-right",
            closeOnClick: true
          });
          localStorage.setItem("jwtToken", `Bearer ${data["token"]}`);
          localStorage.setItem("name", "AryaBook");
          window.location.replace("/")
        }
        CloseHandler()
      } catch (error) {
        toast.error(error.response.data.error, {
          position: "top-right",
          closeOnClick: true
        });
        CloseHandler()
      }
    }
  }


  const [True, SetTrue] = useState(false)
  const CloseHandler = () => {
    SetTrue(false)
  }
  const OpenHandler = () => {
    SetTrue(true)
  }

  return (
    <Container fluid={true} className="p-0">
      <Loader true={True} />
      <Row className="m-0">

        <Col xs="12" className="p-0">

          <div className={`${AuthCss.Background}`}>
            {/* <Link to="/Home"><img className={`${AuthCss.backHome}`} src={require("./../../../../Assets/UserAssets/UserContent/Auth/home.png").default} alt="" /></Link> */}
            <div className={`${AuthCss.Header}`}>
              <div className={`${AuthCss.logo}`}>
                <img src={require("./../../assets/images/logo/LogoAB.png")} alt="" />
              </div>
              <p>کتابخانه مرکزی دانشگاه غالب</p>
              <div className={`${AuthCss.ContactUs}`}>
                {/* <a href=""><img src={require("./../../../../Assets/UserAssets/UserContent/Auth/facebook.png").default} alt="" /></a>
                <a href=""><img src={require("./../../../../Assets/UserAssets/UserContent/Auth/twitter.png").default} alt="" /></a>
                <a href=""><img src={require("./../../../../Assets/UserAssets/UserContent/Auth/linkedin.png").default} alt="" /></a> */}
                {/* <Link className={`${AuthCss.HomeImg}`} to="/Home"><img src={require("./../../../../Assets/UserAssets/Header/home1.png").default} alt="" /></Link> */}

              </div>
            </div>
            <form>
              <div className={`${AuthCss.Content}`}>
                <div className={`${LoginCss.login}`}>
                  <h3 className='PublicFont'>ورود</h3>
                  <div className={`${LoginCss.input}`}>
                    <input
                      className="form-control"
                      type="text"
                      name="mobile"
                      placeholder="شماره موبایل"
                      maxLength="10"
                      required=""
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={mobile}
                      onChange={e => {
                        setMobile(e.target.value);
                      }}
                    />

                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      placeholder="*********"
                      value={password}
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                  <div className={`${LoginCss.button}`}>
                    <button type="button" onClick={LoginHandler}>ورود</button>
                    <Link to={`/pages/auth/signup`}>
                      <button type="button" className={`${LoginCss.button2}`}>ایجاد حساب</button>
                    </Link>
                  </div>
                  <Link to={`/pages/auth/ForgetPassword`}><p>فراموشی رمز</p> </Link>
                  <a href={`/`} className={`${AuthCss.HomeButton}`}>
                    <Button color="success" type="button" className="btn-block" >{"صفحه اصلی"}</Button>
                  </a>

                </div>
              </div>
            </form>
          </div>

        </Col>
      </Row>
    </Container>
  );
}

export default Logins;