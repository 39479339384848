import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import {
  Card,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import CMcss from "./../../../assets/Css/Comment.module.css";
import { useEffect } from "react";
import moment from "jalali-moment";
import { getAllCommentsApi, postCommentApi } from "../../../services/articles";
import "./comment.css";
import { ab_Comments } from "./../../Constant/NewConstant";
const Comment = ({ id }) => {
  const [activeTab, setActiveTab] = useState("1");

  const [comment, setComment] = useState("");
  const [showComment, setShowComment] = useState("");
  const [articel, setArticel] = useState("");
  console.log(window.location.href.split("=")[1]);
  const LoadData = async () => {
    console.log("run");
    const result = await getAllCommentsApi({
      params: {
        articel: window.location.href.split("=")[1],
      },
    });

    setShowComment(result.data);
    console.log("result", result.data);
  };

  console.log("comment", showComment);

  useEffect(() => {
    console.log("run");
    LoadData();
    setArticel(window.location.href.split("=")[1]);
  }, []);

  const SendCm = async (event) => {
    event.preventDefault();
    if (comment === "") {
      toast.error("لطفا نظر خود را وارد کنید");
    } else {
      console.log(comment);
      const body = { comment, articel };
      try {
        const { status } = await postCommentApi({
          body,
        });
        if (status === 201) {
          toast.success(
            "کامنت شما با موفقیت ثبت شد.لطفا تا زمان تایید کامنت خود منتظر بمانید."
            
          );
          setComment('')
          LoadData();
          
        }
      } catch (error) {
        console.log(error);
        toast.error("مشکل در ارسال کامنت", {
          position: "top-right",
        });
      }
    }
  };

  const [VaryingContentone, setVaryingContentone] = useState(false);
  const VaryingContentonetoggle = () => {
    setVaryingContentone(!VaryingContentone);
    setAnswer("");
  };

  const [CommentInfo, setCommentInfo] = useState("");
  const [Answer, setAnswer] = useState("");

  const AnswerComment = (item) => {
    setCommentInfo(item);
    VaryingContentonetoggle();
  };
console.log(window.location)
  const SendAnswer = async (event) => {
    event.preventDefault();
    if (Answer === "") {
      toast.error("لطفا پاسخ خود را وارد کنید");
    } else {
      const body = {
        comment: Answer,
        articel: (window.location.href.split("=")[1]),
        parent: CommentInfo.id,
      };
      try {
        const { status } = await postCommentApi({
          body,
        });
        if (status === 201) {
          toast.success("پاسخ شما با موفقیت ثبت شد");
        }
        setAnswer("")
        setVaryingContentone(false);
      } catch (error) {
        console.log(error);
        toast.error("مشکل در ارسال کامنت");
      }
    }
  };

  return (
    <Fragment>
      <Card className="comment-card">
        <Row className=" product-page-main m-0 PublicFont">
          <Col sm="12">
            <Nav tabs className="border-tab">
              <NavItem id="myTab" role="tablist">
                <NavLink
                  href="#"
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => setActiveTab("1")}
                >
                  ارسال نظر
                </NavLink>
                <div className="material-border"></div>
              </NavItem>
              <NavItem id="myTab" role="tablist">
                <NavLink
                  href="#"
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => setActiveTab("2")}
                >
                  {ab_Comments} {`(${showComment.data?.total})`}
                </NavLink>
                <div className="material-border"></div>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {localStorage.getItem("jwtToken") ? (
                  <Form onSubmit={(e) => SendCm(e)}>
                    <Col sm="12">
                      <textarea
                        className="form-control"
                        rows="5"
                        cols="5"
                        placeholder="متن نظر"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                      <CardFooter>
                        <Button
                          type="submit"
                          className="btn-lg"
                          color="primary"
                        >
                          ارسال
                        </Button>
                      </CardFooter>
                    </Col>
                  </Form>
                ) : (
                  <Col sm="12">برای ثبت نظر ابتدا وارد شوید</Col>
                )}
              </TabPane>
              <TabPane tabId="2">
                {showComment ? (
                  showComment.data.docs.map((item, i) => (
                    <div className={CMcss.Comment}>
                      <div>
                        <div
                          className={CMcss.UserCm}
                          onClick={() => AnswerComment(item)}
                        >
                          <div
                            className={`${CMcss.CmBox} ${"b-primary border-2"}`}
                          >
                            <div className={CMcss.Cmtop}>
                              <h3>{item.user.name}</h3>
                              <time>
                                {moment(item?.createdAt, "YYYY/MM/DD")
                                  .locale("fa")
                                  .format("YYYY/MM/DD") || ""}
                              </time>
                            </div>
                            <div className={CMcss.CmBottom}>
                              <p>{item.comment}</p>
                            </div>
                          </div>
                          <img
                            src={require("./../../../assets/images/Landing/user.png")}
                            alt=""
                          />
                        </div>
                        {item.childes
                          ? item.childes.map((items, i) => (
                              <div className={CMcss.Ans}>
                                <div
                                  className={`${
                                    CMcss.AnsBox
                                  } ${"b-primary border-2"}`}
                                >
                                  <div className={CMcss.Anstop}>
                                    <h3>{items.user.name}</h3>
                                    <time>
                                      {moment(items?.createdAt, "YYYY/MM/DD")
                                        .locale("fa")
                                        .format("YYYY/MM/DD") || ""}
                                    </time>
                                  </div>
                                  <div className={CMcss.AnsBottom}>
                                    <p>{items.comment}</p>
                                  </div>
                                </div>
                                <img
                                  src={require("./../../../assets/images/Landing/admin.png")}
                                  alt=""
                                />
                              </div>
                            ))
                          : ""}
                      </div>
                    </div>
                  ))
                ) : (
                  <Col sm="12">
                    شما اولین نفری باشید که نظر خود را ثبت میکند
                  </Col>
                )}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Card>

      <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle}>
        <ModalHeader toggle={VaryingContentonetoggle}>
          پاسخ به {CommentInfo.user?.name}
        </ModalHeader>
        <ModalBody>
          <p>{CommentInfo.comment}</p>
        </ModalBody>
        <ModalBody>
          <Form onSubmit={(e) => SendAnswer(e)}>
            <FormGroup>
              <Label className="col-form-label" for="message-text">
                {"متن پاسخ : "}
              </Label>
              <Input
                type="textarea"
                className="form-control"
                id="message-text"
                className="PublicFont"
                placeholder="متن پاسخ"
                value={Answer}
                onChange={(e) => setAnswer(e.target.value)}
              ></Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={VaryingContentonetoggle}>
            {"بستن"}
          </Button>
          <Button
            color="primary"
            type="submit"
            onClick={(event) => SendAnswer(event)}
          >
            ارسال پاسخ
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Comment;
