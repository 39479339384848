import React, { Fragment, useState } from 'react';
import { Card, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { AB_NotResult, AB_Heading, AB_AdditionalExplanations, AB_discription, AB_Translator, AB_BookNumber, AB_ReadTime } from './../Constant/NewConstant'

const BookInfo = (props) => {
    
    const [activeTab, setActiveTab] = useState('1');

    return (
        <Fragment>
            <Card>
                <Row className="product-page-main m-0 PublicFont">
                    <Col sm="12">
                        <Nav tabs className="border-tab">
                            <NavItem id="myTab" role="tablist">
                                <NavLink href="#" className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                    {AB_Heading}
                                </NavLink>
                                <div className="material-border"></div>
                            </NavItem>
                            <NavItem id="myTab" role="tablist">
                                <NavLink href="#" className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                    {AB_AdditionalExplanations}
                                </NavLink>
                                <div className="material-border"></div>
                            </NavItem>
                            <NavItem id="myTab" role="tablist">
                                <NavLink href="#" className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                    {AB_discription}
                                </NavLink>
                                <div className="material-border"></div>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col>
                                        {props?.data?.heading ? props.data.heading.map((item, i) => {
                                            return (
                                                <p className="bookHeader">{item.name}</p>
                                            )
                                        }
                                        )
                                            :
                                            <p className="NoResult">{AB_NotResult}</p>

                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                {props.data ?
                                    <div>
                                        <table className="product-page-width">
                                            <tbody className="f-18">
                                                <tr>
                                                    <td> <b>{AB_Translator} &nbsp;&nbsp;&nbsp;:</b></td>
                                                    <td>{props.data?.translator || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td> <b>{AB_BookNumber} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                                    <td>{props.data?.totalPage || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td> <b>{AB_ReadTime} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                                    <td>{props.data?.readingTime || "-"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> : <p className="NoResult">{AB_NotResult}</p>
                                }
                            </TabPane>
                            <TabPane tabId="3">
                                {props.data ?
                                    <Row>
                                        <Col>
                                            <div className="TxtJustify">{props.data?.description || <p className="NoResult">{AB_NotResult}</p>}</div>
                                        </Col>
                                    </Row>
                                    : ""
                                }
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
}

export default BookInfo;