import React, { Fragment } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

const VerticalTimelineComp = () => {
    return (
        <Fragment>
            <VerticalTimeline layout={'1-column'}>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    animate={true}
                >
                    <div className='ContactUsInfo' >
                        <div className='ContactUsUser'>
                            <small>
                                <a href="http://omidshayan.info" target="_blank" rel="noreferrer">
                                    OMIDSHAYAN.INFO
                                </a>
                                <br />
                                AF.OMIDSHAYAN@GMAIL.COM
                                <br />
                                0796968490
                            </small>
                        </div>
                    </div>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                >
                    <small className='AboutMeAf'>
                        امید شایان هستم
                        <br />

                        طراح و برنامه نویس
                        <br />

                        سیستم پیش رو، پروژه مونوگراف من است :)
                        <br />

                        نام پروژه : آریا کتاب ( سیستم مدیریت کتابخانه)
                        <br />

                        زمستان 1400 دانشگاه غالب (هرات-افغانستان)
                        <br />

                        بهترین ویندوزی که میشناسم لینوکسه :)
                    </small>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                >
                    <div className='MatnBahal'>
                        <small>
                            یکی از دل مشغولی هایی که خداوند به انسان عطا می کند، عشق به وطن است                        </small>
                        <small>
                            ما وطن خود را می سازیم!
                        </small>
                    </div>
                </VerticalTimelineElement>


                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    animate={true}
                >
                    <small className='mb-0 AboutMeEn'>
                        I'm Omid Shayan
                        <br />

                        Designer And Programmer
                        <br />

                        The System Ahead is My Dissertation Project :)
                        <br />

                        Project Name: Arya Book (Library Management System)
                        <br />

                        Winter 1400 Ghalib University (Herat-Afghanistan)
                    </small>
                </VerticalTimelineElement>

            </VerticalTimeline>
        </Fragment>
    );
};
export default VerticalTimelineComp;