import React from 'react'
import { Container, Row } from 'reactstrap'
import { Fragment, useState, useEffect } from 'react';
import { BaseApi } from "./../../services/config.json";
import { withRouter } from 'react-router'

import { getApi, putApi } from './../../services/Api/api';
import RScss from "./../../assets/Css/RSlider.module.css"
import "./../../assets/Css/BookSlider.css"
import { ab_Show, ab_More } from '../Constant/NewConstant'


import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Banner from './Banner';
import Banner2 from './Banner2';
import Banner3 from './Banner3';
import Banner4 from './Banner4';



const BackColor = {
    class: {
        "0": "BookSlider-1",
        "1": "BookSlider-2",
        "2": "BookSlider-3",
        "3": "BookSlider-4",
        "4": "BookSlider-5",
        "5": "BookSlider-6",
        "6": "BookSlider-7",
        "7": "BookSlider-8",
        "8": "BookSlider-9",
        "9": "BookSlider-10",
        "10": "BookSlider-11",
        "11": "BookSlider-12",
        "12": "BookSlider-13",
        "13": "BookSlider-14",
        "14": "BookSlider-15",
    }
}

const RSlider = ({ props, history }) => {

    const [slider, setSlider] = useState([]);
    const [show, setShow] = useState(true);

    const GetSliders = async () => {
        try {
            const result = await getApi({
                path: "sliders/show-user"
            });
            setSlider(result.data?.data)
            console.log(result.data?.data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        GetSliders()
    }, [])


    const [widthOfPage, setWidth] = React.useState(window.innerWidth);

    let settings = null;

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };
    React.useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    });


    if (widthOfPage < 1170) {

        settings = {
            dot: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
            autoplay: false,
            cssEase: "linear"
        }
    }
    if (widthOfPage < 800) {

        settings = {
            dot: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            autoplay: false,
            cssEase: "linear"
        }
    }
    if (widthOfPage < 600) {

        settings = {
            dot: true,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
            autoplay: false,
            cssEase: "linear"
        }
    }
    else {
        settings = {
            dot: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            autoplay: false,
            cssEase: "linear"

        }
    }


    const setCategorySearchToLocalStorage = (item) => {
        localStorage.setItem("categoryId", item.category)
    }


    const BookPage = (item) => {
        const id = item._id;
        const slug = item.slug
        IncreaseView(id)
        history.push({
            pathname: `/BookPage/${slug}`,
            search: `?id=${id}`,
            state: { detail: item }
        });
    };

    const IncreaseView = async (id) => {
        try {
            await putApi({ path: `/books/${id}/inc-review` });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Fragment>
            {slider ? slider.map((item, index) =>
                <Container key={index} fluid={true} style={{ margin: " 50px 0", marginTop: "80px" }}>
                    <Row className="second-chart-list third-news-update">
                        <div className={`${RScss.GSlider} ${BackColor.class[index]}`}>
                            <h3 className={`${RScss.SliderTitr}`}>{item.name}</h3>
                            <Link to={`/BookList`}>
                                <div className={RScss.SliderMore}>
                                    <p onClick={() => setCategorySearchToLocalStorage(item)}>
                                        <i className="fa fa-eye"></i>
                                        <br />
                                        {ab_Show}
                                        <br />
                                        {ab_More}
                                    </p>
                                </div>
                            </Link>
                            <Link to={`/BookList`}>
                                <div className={RScss.SliderShowMore}>
                                    <p onClick={() => setCategorySearchToLocalStorage("item")}>
                                        {ab_Show}&nbsp;
                                        {ab_More}&nbsp;
                                    </p>
                                </div>
                            </Link>
                            <svg className={RScss.SvgImg}>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0C31.5006 0.949537 50.52 17.872 56.1955 26.4544L55.986 25.8011L82.4924 58.631C99.3032 79.4521 131.038 79.4521 147.849 58.6309L174.356 25.8011L174.146 26.4544C179.822 17.872 198.844 0.949537 230.349 0H0Z" fill="#f8f8f8" >
                                </path>
                            </svg>
                            <img className={RScss.SliderStaticImg} src={require('./../../assets/images/Landing/BookS1.png')} alt="" />


                            <div className={RScss.SliderBox}>
                                <div className={RScss.holder}>
                                    <Slider {...settings}>
                                        {item?.books ? item.books.map((bookItem, i) =>
                                            <div key={i} className={RScss.SliderItem}>
                                                <div className={`card ${RScss.ItemHolder}`}>
                                                    <div className={RScss.ImageBox}>
                                                        <img src={bookItem.images[0] ? `${BaseApi}${bookItem.images[0]}` : require('./../../assets/images/Landing/NoBook.png')} alt="" />
                                                        <div className={RScss.SliderItemHover}>
                                                            <p onClick={() => BookPage(bookItem)}>
                                                                <i className="icon-eye"></i> نمایش
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className={RScss.information}>
                                                        <h2 className='font-primary'>{bookItem.name}</h2>
                                                        <h3>{bookItem.author}</h3>
                                                    </div>
                                                    <div className={RScss.footer}>
                                                        <div className="product-price">
                                                            {bookItem?.audio?.length > 0 || bookItem?.files?.length > 0 ?
                                                                <>
                                                                    <span className="ViDbackground Font-14"><i className="fa fa-download" > &nbsp; </i> &nbsp; {bookItem.downloades}</span> &nbsp;
                                                                </>
                                                                : ""}
                                                            <span className="ViDbackground Font-14"><i className="fa fa-eye" >&nbsp;</i>&nbsp;{bookItem.review}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ""}
                                    </Slider>

                                </div>

                            </div>

                        </div>
                    </Row>


                    {index == 0 ? <Banner /> : null}
                    {index == 1 ? <Banner2 /> : null}
                    {index == 2 ? <Banner3 /> : null}
                    {index == 3 ? <Banner4 /> : null}


                </Container>

            ) : ""}

        </Fragment>
    )
}

export default withRouter(RSlider)

